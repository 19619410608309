import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

import { palette as defaultPalette } from './defaultPalette';
import { palette as ACDCPalette } from './ACDCPalette';
import { palette as ACLAPalette } from './ACLAPalette';
import { palette as ACLiveUMBPalette } from './ACLiveUMBPalette';
import { palette as AdelphiPalette } from './AdelphiPalette';
import { palette as AMDAPalette } from './AMDAPalette';
import { palette as AmericanPalette } from './AmericanPalette';
import { palette as AuburnPalette } from './AuburnPalette';
import { palette as AUMPalette } from './AUMPalette';
import { palette as AUSPalette } from './AUSPalette';
import { palette as BARPalette } from './BARPalette';
import { palette as BEUPalette } from './BEUPalette';
import { palette as BLCPalette } from './BLCPalette';
import { palette as BMUPalette } from './BMUPalette';
import { palette as BRGPalette } from './BRGPalette';
import { palette as BUFPalette } from './BUFPalette';
import { palette as BTCPalette } from './BTCPalette';
import { palette as CARPalette } from './CARPalette';
import { palette as CCAPalette } from './CCAPalette';
import { palette as CCCPalette } from './CCCPalette';
import { palette as CSCPalette } from './CSCPalette';
import { palette as CSUPalette } from './CSUPalette';
import { palette as CMUPalette } from './CMUPalette';
import { palette as DAYPalette } from './DAYPalette';
import { palette as DECPalette } from './DECPalette';
import { palette as DWUPalette } from './DWUPalette';
import { palette as EDGPalette } from './EDGPalette';
import { palette as EURPalette } from './EURPalette';
import { palette as FIUPalette } from './FIUPalette';
import { palette as FFUPalette } from './FFUPalette';
import { palette as GONPalette } from './GONPalette';
import { palette as HANPalette } from './HANPalette';
import { palette as HCCPalette } from './HCCPalette';
import { palette as HICPalette } from './HICPallette';
import { palette as HWCPalette } from './HWCPalette';
import { palette as HWDPalette } from './HWDPalette';
import { palette as HWEPalette } from './HWEPalette';
import { palette as HDBPalette } from './HDBPalette';
import { palette as HWUKPalette } from './HWUKPalette';
import { palette as JACPalette } from './JACPalette';
import { palette as LAKPalette } from './LAKPalette';
import { palette as LEWPalette } from './LEWPalette';
import { palette as LYCPalette } from './LYCPalette';
import { palette as KUPalette } from './KUPalette';
import { palette as LSUPalette } from './LSUPalette';
import { palette as LYNPalette } from './LYNPalette';
import { palette as LYUPalette } from './LYUPalette';
import { palette as MERPalette } from './MERPalette';
import { palette as MSMPalette } from './MSMPalette';
import { palette as MSTPalette } from './MSTPalette';
import { palette as MMUPalette } from './MMUPalette';
import { palette as MGHPalette } from './MGHPalette';
import { palette as MVUPalette } from './MVUPalette';
import { palette as OMIPalette } from './OMIPalette';
import { palette as OKCPalette } from './OKCPalette';
import { palette as OWUPalette } from './OWUPalette';
import { palette as PBAPalette } from './PBAPalette';
import { palette as PEPPalette } from './PEPPalette';
import { palette as SCHPalette } from './SCHPalette';
import { palette as SCUPalette } from './SCUPalette';
import { palette as SBUPalette } from './SBUPalette';
import { palette as SBOPalette } from './SBOPalette';
import { palette as STAPalette } from './STAPalette';
import { palette as STFPalette } from './STFPalette';
import { palette as STMPalette } from './STMPalette';
import { palette as UCBPalette } from './UCBPalette';
import { palette as UAFPalette } from './UAFPalette';
import { palette as UCFPalette } from './UCFPalette';
import { palette as UICPalette } from './UICPalette';
import { palette as UISPalette } from './UISPalette';
import { palette as UMAPalette } from './UMAPalette';
import { palette as UMBPalette } from './UMBPalette';
import { palette as UMUPalette } from './UMUPalette';
import { palette as UNEPalette } from './UNEPalette';
import { palette as UNRPalette } from './UNRPalette';
import { palette as UOPPalette } from './UOPPalette';
import { palette as UOCPalette } from './UOCPalette';
import { palette as UODPalette } from './UODPalette';
import { palette as UORPalette } from './UORPalette';
import { palette as UPDPalette } from './UPDPalette';
import { palette as USCPalette } from './USCPalette';
import { palette as UTAPalette } from './UTAPalette';
import { palette as UTEPalette } from './UTEPalette';
import { palette as UTSAPalette } from './UTSAPalette';
import { palette as UWEPalette } from './UWEPalette';
import { palette as UWPPalette } from './UWPPalette';
import { palette as UWRPalette } from './UWRPalette';
import { palette as UWSPalette } from './UWSPalette';
import { palette as UWWPalette } from './UWWPalette';
import { palette as UWYPalette } from './UWYPalette';
import { palette as RANPalette } from './RANPalette';
import { palette as RMUPalette } from './RMUPalette';
import { palette as RNBPalette } from './RNBPalette';
import { palette as RUCPalette } from './RUCPalette';
import { palette as RVUPalette } from './RVUPalette';
import { palette as SHUPalette } from './SHUPalette';
import { palette as SMMPalette } from './SMMPalette';
import { palette as SMCPalette } from './SMCPalette';
import { palette as SRUPalette } from './SRUPalette';
import { palette as SWUPalette } from './SWUPalette';
import { palette as TCCPalette } from './TCCPalette';
import { palette as TNSPalette } from './TNSPalette';
import { palette as TOWPalette } from './TOWPalette';
import { palette as TULPalette } from './TULPalette';
import { palette as TUSPalette } from './TUSPalette';
import { palette as JHUPalette } from './JHUPalette';
import { palette as VWUPalette } from './VWUPalette';
import { palette as WDUPalette } from './WDUPalette';
import { palette as WILPalette } from './WILPalette';
import { palette as WITPalette } from './WITPalette';
import { palette as WJCPalette } from './WJCPalette';
import { palette as WTCPalette } from './WTCPalette';
import { palette as WNEPalette } from './WNEPalette';
import { palette as WOUPalette } from './WOUPalette';
import { palette as WUSPalette } from './WUSPalette';
import { palette as YCPPalette } from './YCPPalette';

import {
  $blackOpacity,
  $white,
  $whiteOpacity,
  $greyDark,
  $shorelightBlue,
  $spinnerColor,
  $succesBarColor,
  $successMain,
  $disabled,
  $disabledBackground,
  $disabledDark,
  $fieldColor,
  $fieldsetBackground,
  $fieldsetBackgroundDark,
  $inputBorder,
  $errorMain,
  $errorLight
} from './commonColors';

const schoolToPalette = {
  default: defaultPalette,
  ACDC: ACDCPalette,
  ACL: ACLiveUMBPalette,
  ACLA: ACLAPalette,
  'ACL-UMB': ACLiveUMBPalette,
  ADU: AdelphiPalette,
  AMDA: AMDAPalette,
  AMU: AmericanPalette,
  AU: AuburnPalette,
  AUM: AUMPalette,
  AUS: AUSPalette,
  BAR: BARPalette,
  BEU: BEUPalette,
  BLC: BLCPalette,
  BMU: BMUPalette,
  BRG: BRGPalette,
  BUF: BUFPalette,
  BTC: BTCPalette,
  CAR: CARPalette,
  CCA: CCAPalette,
  CCC: CCCPalette,
  CSC: CSCPalette,
  CSU: CSUPalette,
  'CSU-live': CSUPalette,
  CMU: CMUPalette,
  DAY: DAYPalette,
  'DAY-live': DAYPalette,
  DEC: DECPalette,
  DWU: DWUPalette,
  EDG: EDGPalette,
  EUR: EURPalette,
  FIU: FIUPalette,
  FFU: FFUPalette,
  GON: GONPalette,
  HAN: HANPalette,
  HCC: HCCPalette,
  HIC: HICPalette,
  HMA: HWUKPalette,
  HWC: HWCPalette,
  HWD: HWDPalette,
  HWM: HWDPalette,
  HWE: HWEPalette,
  HDB: HDBPalette,
  HWUK: HWUKPalette,
  JAC: JACPalette,
  JHU: JHUPalette,
  LAK: LAKPalette,
  LEW: LEWPalette,
  LYC: LYCPalette,
  KU: KUPalette,
  LSU: LSUPalette,
  LYN: LYNPalette,
  LYU: LYUPalette,
  MER: MERPalette,
  MSM: MSMPalette,
  MST: MSTPalette,
  MMU: MMUPalette,
  MGH: MGHPalette,
  MVU: MVUPalette,
  OMI: OMIPalette,
  OKC: OKCPalette,
  OWU: OWUPalette,
  PBA: PBAPalette,
  PEP: PEPPalette,
  RAN: RANPalette,
  RMU: RMUPalette,
  RNB: RNBPalette,
  RUC: RUCPalette,
  RVU: RVUPalette,
  'STF-live': STFPalette,
  SBU: SBUPalette,
  SBO: SBOPalette,
  SCH: SCHPalette,
  SCU: SCUPalette,
  SHU: SHUPalette,
  SMM: SMMPalette,
  SMC: SMCPalette,
  STA: STAPalette,
  STM: STMPalette,
  SRU: SRUPalette,
  SWU: SWUPalette,
  TOW: TOWPalette,
  TCC: TCCPalette,
  TNS: TNSPalette,
  TUL: TULPalette,
  TUS: TUSPalette,
  'UCB-live': UCBPalette,
  UAF: UAFPalette,
  UCF: UCFPalette,
  UIC: UICPalette,
  UIS: UISPalette,
  'UMA-live': UMAPalette,
  UMB: UMBPalette,
  UMU: UMUPalette,
  UNE: UNEPalette,
  UNR: UNRPalette,
  UOP: UOPPalette,
  UOC: UOCPalette,
  UOD: UODPalette,
  UOR: UORPalette,
  UPD: UPDPalette,
  USC: USCPalette,
  'USC-live': USCPalette,
  UTA: UTAPalette,
  UTE: UTEPalette,
  UTSA: UTSAPalette,
  UWE: UWEPalette,
  UWP: UWPPalette,
  UWR: UWRPalette,
  UWS: UWSPalette,
  UWW: UWWPalette,
  UWY: UWYPalette,
  VWU: VWUPalette,
  WDU: WDUPalette,
  WIL: WILPalette,
  WIT: WITPalette,
  WJC: WJCPalette,
  WTC: WTCPalette,
  WNE: WNEPalette,
  WOU: WOUPalette,
  WUS: WUSPalette,
  YCP: YCPPalette
};

export const buildTheme = schoolCode => {
  if (!schoolCode) {
    return;
  }

  const schoolPalette = schoolToPalette[schoolCode] || schoolToPalette.default;

  const {
    primaryMain,
    primaryMainOpacity,
    primaryDark,
    primaryText,
    secondaryMain,
    secondaryMainOpacity,
    secondaryLight,
    secondaryDark,
    secondaryText,
    tertiaryMain,
    contrastText,
    logoContainer,
    logoContainerMobile,
    headerColor,
    headerPaddingTop,
    headerPaddingBottom,
    nextIcon = {},
    formNavBar = {},
    formPageActions = {},
    formTextBlock = {}
  } = schoolPalette;

  const customThemeFormPageActions = {
    continueIconColor: primaryText,
    continueSupportLinkColor: primaryText,
    continueTextColor: $blackOpacity,
    ...formPageActions
  };

  const customThemeFormPageTitle = {
    formPageTitleIconTextColor: $white,
    formPageTitleIconBackgroundColor: primaryText
  };

  const customThemeFormTextBlock = {
    alertBackgroundColor: primaryMain,
    alertFontColor: $white,
    alertIconBackgroundColor: secondaryLight,
    ...formTextBlock
  };

  const customThemeWizardNavBar = {
    stepperBackgroundColor: tertiaryMain,
    stepperBackgroundColorMobile: primaryMain,
    currentStepButtonTextColor: primaryMain,
    ...formNavBar
  };
  const customThemeSuccessBar = {
    successBarBackgroundColor: $succesBarColor,
    successBarTextColor: $blackOpacity
  };

  const customThemeFileInstructions = {
    header: secondaryText,
    text: primaryText
  };

  const customThemeReviewApplicationPage = {
    expansionPanelRootBackgroundColor: $fieldsetBackground
  };

  return createMuiTheme({
    palette: {
      primary: {
        main: primaryMain,
        dark: primaryDark,
        opacity: primaryMainOpacity
      },
      secondary: {
        main: secondaryMain,
        light: secondaryLight,
        dark: secondaryDark,
        opacity: secondaryMainOpacity
      },
      error: {
        main: $errorMain,
        light: $errorLight
      },
      success: {
        main: $successMain
      },
      text: {
        secondary: $greyDark,
        secondaryDark: $shorelightBlue,
        hint: $white,
        hintOpacity: $whiteOpacity,
        //we use our own variables instead fo overriding 'primary' and 'secondary'
        //because those are used in input fields and labels, so far we only care to
        //set color for instructions texts and header
        primaryText,
        secondaryText,
        contrast: contrastText || $white
      },
      fieldset: {
        main: $fieldsetBackground,
        dark: $fieldsetBackgroundDark,
        text: $fieldColor,
        border: $inputBorder
      },
      action: {
        disabled: $disabled,
        disabledBackground: $disabledBackground,
        disabledDark: $disabledDark,
        spinnerColor: $spinnerColor
      },
      custom: {
        themeFileInstructions: customThemeFileInstructions,
        themeFormPageActions: customThemeFormPageActions,
        themeFormPageTitle: customThemeFormPageTitle,
        themeFormTextBlock: customThemeFormTextBlock,
        themeReviewApplicationPage: customThemeReviewApplicationPage,
        themeSuccessBar: customThemeSuccessBar,
        themeWizardNavBar: customThemeWizardNavBar
      }
    },
    typography: {
      fontFamily: 'MyriadPro, sans-serif',
      h5: {
        fontSize: 20,
        textTransform: 'uppercase',
        fontWeight: 'bold'
      },
      h6: {
        fontSize: 18
      }
    },
    //This is the total Height of SchoolHeader DOM in pixels
    //used to position the navbar fixed at top in desktop
    header: {
      backgroundColor: headerColor,
      containerHeight: 96,
      containerHeightMobile: 128,
      logoContainer: {
        height: 48,
        marginTop: 16,
        ...(logoContainer || {})
      },
      logoContainerMobile: {
        height: 40,
        ...(logoContainerMobile || {})
      },
      paddingTop: headerPaddingTop,
      paddingBottom: headerPaddingBottom
    },
    overrides: {
      MuiButton: {
        root: {
          fontWeight: 'bold'
        },
        outlinedSecondary: {
          color: $fieldColor,
          borderColor: $fieldColor,
          backgroundColor: $white
        }
      },
      MuiFab: {
        primary: {
          backgroundColor: nextIcon.backgroundColor ? nextIcon.backgroundColor : primaryMain,
          color: nextIcon.color ? nextIcon.color : $white
        }
      },
      MuiTypography: {
        root: {},
        colorInherit: {
          color: $fieldColor
        }
      }
    }
  });
};
