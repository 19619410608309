import { $white } from './commonColors';

const red = '#881C1C';
const darkRed = '#661414';

const primaryMain = red;
const primaryMainOpacity = 'rgba(136, 28, 28, 0.9)';
const primaryDark = darkRed;
const secondaryMain = red;
const secondaryMainOpacity = '#DEDEDE';
const secondaryLight = red;
const secondaryDark = darkRed;
const tertiaryMain = darkRed;

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryDark,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryDark,
  secondaryText: secondaryMain,
  tertiaryMain,
  contrastText: $white,
  logoContainer: {
    height: 32,
    marginTop: 24
  },
  logoContainerMobile: {
    height: 24,
    marginBottom: 25
  },
  headerPaddingTop: 38,
  headerPaddingBottom: 38,
  formTextBlock: {
    alertBackgroundColor: tertiaryMain,
    alertIconBackgroundColor: $white
  }
};
