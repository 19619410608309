const primaryMain = '#41B6E6';
const primaryMainOpacity = 'rgba(65, 182, 230, 0.9)';
const secondaryMain = '#003087';
const secondaryMainOpacity = 'rgba(0, 48, 135, 0.5)';
const secondaryLight = '#D22630';
const tertiaryMain = '#0B2441';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
