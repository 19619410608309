import React from 'react';
import PropTypes from 'prop-types';

import compose from 'compose-function';
import { connect } from 'react-redux';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from '../DialogTitle';
import VerifyDialogInstructions from './VerifyDialogInstructions';
import VerifyDialogContinueApp from './VerifyDialogContinueApp';
import VerifyCodeForm from '../../VerifyCodeForm/VerifyCodeForm';
import AdmissionsPortalApplicantError from '../AdmissionsPortalApplicantError';
import PoweredByPanel from '../../PoweredByPanel/PoweredByPanel';

import {
  continueApplication,
  checkVerificationCode,
  cancelVerification,
  addApplicationToContact,
  generateVerificationCode
} from '../../../actions/application';

import { getQueryParams } from '../../../utils/forms';

import {
  HARD_STOP_APPLICATION_SCHOOLS,
  APPLICANT_SOURCE_ADMISSIONS_PORTAL
} from '../../../constants/constants';

const styles = theme => ({
  contentRoot: {
    paddingTop: theme.spacing(),
    overflowY: 'unset'
  }
});

export class VerifyDialog extends React.PureComponent {
  handleDialogClose = () => {
    this.props.cancelVerification();
  };

  handleSubmitVerificationCode = verificationCode => {
    const { submitVerificationCode } = this.props;

    submitVerificationCode(verificationCode);
  };

  renderVerificationForm = () => {
    const {
      requestingVerificationCode,
      sendVerificationCode,
      verifyingCode,
      invalidCode,
      expiredCode,
      verificationCodeError,
      existingStudentDetails,
      verificationCodeGenerated
    } = this.props;

    return (
      <>
        <VerifyDialogInstructions
          email={existingStudentDetails ? existingStudentDetails.email : ''}
        />
        <VerifyCodeForm
          invalidCode={invalidCode}
          expiredCode={expiredCode}
          verifyingCode={verifyingCode}
          sendVerificationCode={sendVerificationCode}
          requestingVerificationCode={requestingVerificationCode}
          verificationCodeError={verificationCodeError}
          verificationCodeGenerated={verificationCodeGenerated}
          onSubmitVerificationCode={this.handleSubmitVerificationCode}
        />
      </>
    );
  };

  renderVerificationSuccessContent = () => {
    const {
      retrievingApplication,
      continueApplication,
      addApplicationToContact,
      existingContactWithEmail
    } = this.props;
    return (
      <VerifyDialogContinueApp
        continueApplication={continueApplication}
        retrievingApplication={retrievingApplication}
        addApplicationToContact={addApplicationToContact}
        existingContactWithEmail={existingContactWithEmail}
      />
    );
  };

  renderHardStopContent = () => {
    const { existingStudentDetails, supportEmail } = this.props;
    return (
      <AdmissionsPortalApplicantError
        existingAgencyDetails={existingStudentDetails.agency}
        supportEmail={supportEmail}
      />
    );
  };

  getDialog = () => {
    const { codeVerified, existingContactWithEmail, existingStudentDetails } = this.props;
    const currentSchool = getQueryParams().cfm_school;

    //As part of RE-1510, HARD_STOP_APPLICATION_SCHOOLS was modified not to check Portal Source against ACLive, to allow all student to apply
    //TO-DO: come back to this once Admissions Portal supports AC Live applications
    if (
      HARD_STOP_APPLICATION_SCHOOLS.includes(currentSchool) &&
      existingContactWithEmail &&
      existingStudentDetails &&
      existingStudentDetails.applicantSource === APPLICANT_SOURCE_ADMISSIONS_PORTAL
    ) {
      return {
        dialogTitle: 'Contact your Agent',
        dialogContent: this.renderHardStopContent()
      };
    }

    return {
      dialogTitle: existingContactWithEmail ? 'Welcome Back' : 'Verify your identity',
      dialogContent: codeVerified
        ? this.renderVerificationSuccessContent()
        : this.renderVerificationForm()
    };
  };

  render() {
    const { codeVerified, isVerificationRequired, fullScreen, classes } = this.props;

    const { dialogTitle, dialogContent } = this.getDialog();

    return (
      <Dialog open={isVerificationRequired} fullScreen={fullScreen}>
        <DialogTitle
          showCloseButton={!codeVerified}
          onDialogClose={this.handleDialogClose}
          title={dialogTitle}
        />
        <DialogContent classes={{ root: classes.contentRoot }}>
          {dialogContent}
          <PoweredByPanel />
        </DialogContent>
      </Dialog>
    );
  }
}

VerifyDialog.propTypes = {
  codeVerified: PropTypes.bool,
  verifyingCode: PropTypes.bool,
  invalidCode: PropTypes.bool,
  expiredCode: PropTypes.bool,
  requestingVerificationCode: PropTypes.bool,
  retrievingApplication: PropTypes.bool,
  verificationCodeGenerated: PropTypes.bool,
  verificationCodeError: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  isVerificationRequired: PropTypes.bool.isRequired,
  existingContactWithEmail: PropTypes.bool.isRequired,
  existingStudentDetails: PropTypes.shape({
    applicantSource: PropTypes.string,
    email: PropTypes.string,
    agency: PropTypes.object,
    schools: PropTypes.array
  }),
  cancelVerification: PropTypes.func.isRequired,
  continueApplication: PropTypes.func.isRequired,
  submitVerificationCode: PropTypes.func.isRequired,
  addApplicationToContact: PropTypes.func.isRequired,
  sendVerificationCode: PropTypes.func.isRequired,
  supportEmail: PropTypes.string,
  //provided by withMobileDialog from MaterialUI
  fullScreen: PropTypes.bool.isRequired
};

VerifyDialog.defaultProps = {
  codeVerified: false,
  verifyingCode: false,
  expiredCode: false,
  invalidCode: false,
  retrievingApplication: false,
  requestingVerificationCode: false,
  verificationCodeError: false,
  verificationCodeGenerated: false
};

function mapStateToProps(state) {
  const {
    application: {
      codeVerified,
      verifyingCode,
      expiredCode,
      invalidCode,
      retrievingApplication,
      verificationCodeError,
      requireEmailVerification,
      requestingVerificationCode,
      verificationCodeGenerated,
      existingContactWithEmail,
      existingStudentDetails
    },
    schema: { supportEmail }
  } = state;
  return {
    codeVerified,
    verifyingCode,
    expiredCode,
    invalidCode,
    retrievingApplication,
    verificationCodeError,
    requestingVerificationCode,
    isVerificationRequired: requireEmailVerification || existingContactWithEmail,
    verificationCodeGenerated,
    existingContactWithEmail,
    existingStudentDetails,
    supportEmail
  };
}

const mapDispatchToProps = {
  continueApplication,
  addApplicationToContact,
  cancelVerification,
  submitVerificationCode: checkVerificationCode,
  sendVerificationCode: generateVerificationCode
};

export default compose(
  withMobileDialog({ breakpoint: 'xs' }),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(VerifyDialog);
