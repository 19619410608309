import React from 'react';
import PropTypes from 'prop-types';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Info from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';

import withStyles from '@material-ui/core/styles/withStyles';

import PoweredByShorelight from '@shorelight/ui-common-components/cjs/images/PoweredByShorelight';

const styles = theme => ({
  rootPanel: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    }
  },
  expanded: {},
  expandIcon: {
    color: theme.palette.action.disabledBackground,
    '&$expanded': {
      transform: 'translateY(-50%) rotate(0deg)'
    }
  },
  text: {
    color: theme.palette.fieldset.text
  }
});

export class PoweredByPanel extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <ExpansionPanel
        classes={{
          root: classes.rootPanel
        }}
        square
      >
        <ExpansionPanelSummary
          expandIcon={<Info />}
          classes={{
            expanded: classes.expanded,
            expandIcon: classes.expandIcon
          }}
        >
          <PoweredByShorelight />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className={classes.text}>
            We partner international students with leading U.S. universities and support them
            throughout their academic career.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

PoweredByPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PoweredByPanel);
