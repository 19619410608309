const primaryMain = '#000';
const primaryMainOpacity = 'rgba(0, 0, 0, 0.9)';
const secondaryMain = '#fb0';
const secondaryMainOpacity = 'rgba(255, 187, 0, 0.5)';
const secondaryLight = '#CC9900';
const tertiaryMain = '#3C3C3C';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
