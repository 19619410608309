import { $black } from './commonColors';

const primaryMain = '#1E1E1E';
const primaryMainOpacity = 'rgba(30, 30, 30, 0.9)';
const secondaryMain = '#981E32';
const secondaryMainOpacity = 'rgba(152, 30, 50, 0.5)';
const secondaryLight = '#FDD023';
const tertiaryMain = '#981E32';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: primaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: $black
  }
};
