import { $black } from './commonColors';

const primaryMain = '#FFB500';
const primaryMainOpacity = 'rgba(255, 181, 0, 0.9)';
const secondaryMain = '#4F2C1D';
const secondaryMainOpacity = 'rgba(79, 44, 29, 0.5)';
const secondaryLight = $black;

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: secondaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryLight,
  contrastText: secondaryMain,
  logoContainer: {
    height: 50,
    marginTop: 12
  },
  formNavBar: {
    stepperBackgroundColor: secondaryMain,
    stepperBackgroundColorMobile: secondaryMain,
    currentStepButtonTextColor: secondaryMain
  },
  formPageActions: {
    continueIconColor: secondaryMain,
    continueSupportLinkColor: secondaryMain
  },
  formTextBlock: {
    alertBackgroundColor: primaryMain,
    alertFontColor: secondaryMain,
    alertIconBackgroundColor: secondaryLight
  }
};
