const primaryMain = '#009CDE';
const primaryMainOpacity = 'rgba(0, 156, 222, 0.9)';
const secondaryMain = '#333F48';
const secondaryMainOpacity = 'rgba(51, 63, 72, 0.5)';
const secondaryLight = '#003C71';
const tertiaryMain = '#003C71';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
