import React from 'react';
import PropTypes from 'prop-types';
import compose from 'compose-function';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';

import ReviewApplication from '@shorelight/super-form/components/ReviewApplication/ReviewApplication';
import ReviewSignatureForm from '@shorelight/super-form/components/ReviewSignatureForm';

import {
  submitApplication,
  editPageApplication,
  openConsentDialog
} from '../../actions/application';

import { SIGNATURE_FORM_NAME } from '../../constants/constants';

const styles = theme => ({
  mainContainer: {
    backgroundColor: theme.palette.fieldset.dark
  }
});

class ReviewApplicationContainer extends React.Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  handleSubmit = () => {
    const { submitApplication, formValues } = this.props;
    submitApplication(formValues);
  };

  renderCustomSubmit = () => {
    return (
      <ReviewSignatureForm
        consentAccepted={this.props.consentAccepted}
        formSubmitName={SIGNATURE_FORM_NAME}
        formSubmitting={this.props.submitting}
        formSubmitError={this.props.submitError}
        handleOpenConsentDialog={this.props.openConsentDialog}
        handleSubmit={this.handleSubmit}
      />
    );
  };

  render() {
    return (
      <div className={this.props.classes.mainContainer}>
        {this.props.customHeader}
        <ReviewApplication
          customSubmit={this.renderCustomSubmit}
          schema={this.props.schema}
          formValues={this.props.formValues}
          editPageApplication={this.props.editPageApplication}
          formSubmitting={this.props.submitting}
          formSubmitError={this.props.submitError && this.props.submitError.content}
        />
      </div>
    );
  }
}

ReviewApplicationContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  editPageApplication: PropTypes.func.isRequired,
  submitApplication: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  submitError: PropTypes.bool,
  submitting: PropTypes.bool,
  openConsentDialog: PropTypes.func.isRequired,
  consentAccepted: PropTypes.bool,
  customHeader: PropTypes.element
};

ReviewApplicationContainer.defaultProps = {
  submitting: false,
  consentAccepted: false
};

const mapStateToProps = state => ({
  submitError: state.application.submitError,
  submitting: state.application.submitting,
  formValues: state.application.values,
  schema: state.schema.schema,
  consentAccepted: state.application.consentAccepted
});

const mapDispatchToProps = {
  submitApplication,
  editPageApplication,
  openConsentDialog
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ReviewApplicationContainer);
