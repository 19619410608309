const primaryMain = '#14234b';
const primaryMainOpacity = 'rgba(20, 35, 75, 0.9)';
const secondaryMain = '#e51937';
const secondaryMainOpacity = 'rgba(229, 25, 56, 0.5)';
const secondaryLight = '#92BDE3';
const tertiaryMain = '#b2b1ae';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
