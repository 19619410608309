const primaryMain = '#333333';
const primaryMainOpacity = 'rgba(51, 51, 51, 0.9)';
const secondaryMain = '#1B3E6E';
const secondaryMainOpacity = 'rgba(27, 62, 110, 0.5)';
const secondaryLight = '#C19B77';
const tertiaryMain = '#F6F6F6';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  contrastText: primaryMain,
  logoContainer: {
    height: 80,
    marginTop: 0
  },
  logoContainerMobile: {
    height: 60
  },
  headerColor: tertiaryMain,
  formNavBar: {
    stepperBackgroundColor: secondaryMain,
    stepperBackgroundColorMobile: secondaryMain
  }
};
