const primaryMain = '#113A93';
const primaryMainOpacity = 'rgba(17, 58, 147, 0.9)';
const secondaryMain = '#2AC4EA';
const secondaryMainOpacity = 'rgba(42, 196, 234, 0.5)';
const secondaryLight = '#1569D6';
const tertiaryMain = '#B6B8B6';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
