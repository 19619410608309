const primaryMain = '#192857';
const primaryMainOpacity = 'rgba(25, 40, 87, 0.9)';
const secondaryMain = '#E55526';
const secondaryMainOpacity = 'rgba(229, 85, 38, 0.5)';
const secondaryLight = '#005289';
const tertiaryMain = '#5C6F7B';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
