const primaryMain = '#17214A';
const primaryMainOpacity = 'rgba(23, 33, 74, 0.9)';
const secondaryMain = '#D70036';
const secondaryMainOpacity = 'rgba(215, 0, 54, 0.5)';
const secondaryLight = '#F80228';
const tertiaryMain = '#05023B';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  logoContainerMobile: {
    height: 30,
    marginBottom: 20
  }
};
