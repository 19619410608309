const primaryMain = '#CC0033';
const primaryMainOpacity = 'rgba(204, 0, 51, 0.9)';
const secondaryMain = '#000000';
const secondaryMainOpacity = 'rgba(0, 0, 0, 0.5)';
const secondaryLight = '#8E0D18';
const tertiaryMain = '#000000';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
