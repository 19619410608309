const primaryMain = '#752F8A';
const primaryMainOpacity = 'rgba(117, 47, 138, 0.9)';
const secondaryMain = '#000000';
const secondaryMainOpacity = 'rgba(0, 0, 0, 0.5)';
const secondaryLight = '#000000';
const tertiaryMain = '#000000';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
