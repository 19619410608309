import React from 'react';
import PropTypes from 'prop-types';
import compose from 'compose-function';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, getFormSyncErrors, getFormAsyncErrors } from 'redux-form';

import withStyles from '@material-ui/core/styles/withStyles';

import SchoolHeader from './SchoolHeader';
import SchoolFavicon from './SchoolFavicon';
import ReduxSuperForm from './ReduxSuperForm';

import LicenseFooter from '../LicenseFooter/LicenseFooter';
import CustomTitle from '../WelcomePage/CustomTitle';
import WelcomePage from '../WelcomePage/WelcomePage';
import ReviewApplicationContainer from '../ReviewApplication/ReviewApplicationContainer';
import SuccessPage from '../SuccessPage/SuccessPage';

import {
  reviewApplication,
  goToPageApplication,
  updatePageProgress,
  highlightMissingFields
} from '../../actions/application';
import {
  fileUpload,
  selectFiles,
  removeFile,
  selectManifestFile,
  removeManifestFile
} from '../../actions/upload';

import { isAddApplication, isReviewPage } from '@shorelight/super-form/utils/SuperFormUtils';

import { canCreateLead } from '../../utils/forms';

import { SCHEMA_FORM_NAME, EMAIL_FIELD_ID, LIVE_SCHOOL_SUFFIX } from '../../constants/constants';

const styles = theme => ({
  mainContainer: {
    backgroundColor: theme.palette.fieldset.dark,
    minHeight: `calc(100vh - ${theme.header.containerHeight}px)`,
    [theme.breakpoints.down('xs')]: {
      minHeight: `calc(100vh - ${theme.header.containerHeightMobile}px)`
    }
  }
});

export class SuperFormContainer extends React.Component {
  componentDidMount() {
    window.onbeforeunload = this.onBeforeUnload.bind(this);
  }

  onBeforeUnload() {
    const { existingContactWithEmail, submitionSuccess } = this.props;
    //needs to return null or undefined to avoid showing any alert to the user
    return existingContactWithEmail || submitionSuccess ? null : true;
  }

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.handleSubmit(this.props.formValues);
  };

  getCustomTitle() {
    const { currentForm } = this.props;

    return currentForm.data.cfm_level === 'certificate' && currentForm.subtitle ? (
      <CustomTitle title={currentForm.subtitle} />
    ) : (
      undefined
    );
  }

  isLiveSchool = currentForm => {
    return currentForm ? currentForm.data.school.endsWith(LIVE_SCHOOL_SUFFIX) : false;
  };

  renderContent = inWelcomePage => {
    const { currentForm, currentPageIndex } = this.props;

    const schoolSubTitle = this.getCustomTitle();
    const isLiveSchool = this.isLiveSchool(currentForm);

    if (inWelcomePage) {
      return <WelcomePage customHeader={schoolSubTitle} isLiveSchool={isLiveSchool} />;
    } else if (this.props.reviewingApplication) {
      return <ReviewApplicationContainer customHeader={schoolSubTitle} />;
    } else if (this.props.submitionSuccess) {
      return (
        <SuccessPage
          currentLevel={currentForm.data.cfm_level}
          email={this.props.emailCompleted}
          schoolUrl={this.props.schoolUrl}
        />
      );
    }

    const isViewingReviewPage =
      currentForm.pages[currentPageIndex] && isReviewPage(currentForm.pages[currentPageIndex]);
    const isNavDisabled =
      (this.props.invalidEmail || !canCreateLead(this.props.formValues, this.props.syncErrors)) &&
      (isViewingReviewPage || !this.props.isExistingContact);

    const uploaderContext = {
      selectedFiles: this.props.selectedFiles,
      uploadedFiles: this.props.uploadedFiles,
      uploading: this.props.uploading,
      uploadError: this.props.uploadError,
      handleFileUpload: this.props.fileUpload,
      handleSelectFiles: this.props.handleSelectFiles,
      handleSelectSingleFile: this.props.handleSelectSingleFile,
      handleRemoveFile: this.props.handleRemoveFile,
      handleRemoveSingleFile: this.props.handleRemoveSingleFile,
      defaultInstructions: true
    };

    return (
      <div className={this.props.classes.mainContainer}>
        <ReduxSuperForm
          dispatch={this.props.dispatch}
          formName={SCHEMA_FORM_NAME}
          schema={currentForm}
          currentPageIndex={currentPageIndex}
          progressMap={this.props.progressMap}
          initialValues={this.props.initialValues}
          formValues={this.props.formValues}
          invalidEmail={this.props.invalidEmail}
          isNavigationDisabled={isNavDisabled}
          isSaving={this.props.isSaving}
          lastSaveTime={this.props.lastSaveTime}
          supportEmail={this.props.supportEmail}
          formSyncErrors={this.props.formSyncErrors}
          formAsyncErrors={this.props.formAsyncErrors}
          handleSelectPage={this.props.handleSelectPage}
          handleUpdatePageProgress={this.props.handleUpdatePageProgress}
          handleHighlightMissingFields={this.props.handleHighlightMissingFields}
          handleSubmit={this.handleSubmit}
          uploaderContext={uploaderContext}
        />
      </div>
    );
  };

  render() {
    const { currentForm, invalidEmail, submitionSuccess } = this.props;
    const inWelcomePage = invalidEmail && !submitionSuccess;

    return (
      <>
        <SchoolFavicon schoolCode={currentForm.data.school} />
        <SchoolHeader
          schoolCode={currentForm.data.school}
          title={currentForm.Name}
          subtitle={currentForm.subtitle}
        />
        {this.renderContent(inWelcomePage)}
        <LicenseFooter withLowHeight={inWelcomePage} withSuccesStyle={submitionSuccess} />
      </>
    );
  }
}

SuperFormContainer.propTypes = {
  asyncErrors: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  currentForm: PropTypes.object.isRequired,
  currentPageIndex: PropTypes.number.isRequired,
  existingContactWithEmail: PropTypes.bool.isRequired,
  fieldIds: PropTypes.array.isRequired,
  formSyncErrors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  invalidEmail: PropTypes.bool.isRequired,
  isExistingContact: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  progressMap: PropTypes.object.isRequired,
  reviewingApplication: PropTypes.bool.isRequired,
  schoolUrl: PropTypes.string.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  submitionSuccess: PropTypes.bool.isRequired,
  supportEmail: PropTypes.string.isRequired,
  syncErrors: PropTypes.object.isRequired,
  uploadError: PropTypes.bool.isRequired,
  uploadedFiles: PropTypes.array.isRequired,
  uploading: PropTypes.bool.isRequired
};

SuperFormContainer.defaultProps = {
  syncErrors: {},
  asyncErrors: {},
  isSaving: false
};

const selector = formValueSelector(SCHEMA_FORM_NAME); // <-- same as form name

const mapStateToProps = state => {
  const {
    invalidEmail,
    initialEmail,
    emailCompleted,
    existingContactWithEmail,
    saving,
    lastSaveTime,
    review,
    completed,
    params,
    values,
    progressMap,
    pageNumber
  } = state.application;
  const { schema, formids, schoolUrl, supportEmail } = state.schema;
  const { uploading, selectedFiles, uploadedFiles, uploadError } = state.upload;

  const initialValues = {
    [EMAIL_FIELD_ID]: initialEmail, //set email from the welcome page, may be overridden by incoming data
    ...schema.data,
    ...values
  };

  return {
    invalidEmail,
    emailCompleted,
    existingContactWithEmail,
    isExistingContact: isAddApplication(params),
    isSaving: saving,
    lastSaveTime,
    reviewingApplication: review,
    submitionSuccess: completed,
    progressMap,
    currentPageIndex: pageNumber,
    currentForm: schema,
    fieldIds: formids,
    schoolUrl,
    supportEmail,
    initialValues,
    formValues: formids && schema ? selector(state, ...formids, 'acceptedConsent') : {},
    formSyncErrors: getFormSyncErrors(SCHEMA_FORM_NAME)(state),
    formAsyncErrors: getFormAsyncErrors(SCHEMA_FORM_NAME)(state),
    uploading,
    selectedFiles,
    uploadedFiles,
    uploadError
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  ...bindActionCreators(
    {
      handleHighlightMissingFields: highlightMissingFields,
      handleSelectPage: goToPageApplication,
      handleSubmit: reviewApplication,
      handleUpdatePageProgress: updatePageProgress,
      fileUpload,
      handleSelectFiles: selectFiles,
      handleSelectSingleFile: selectManifestFile,
      handleRemoveFile: removeFile,
      handleRemoveSingleFile: removeManifestFile
    },
    dispatch
  )
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SuperFormContainer);
