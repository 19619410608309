const primaryMain = '#003C71';
const primaryMainOpacity = 'rgba(0, 60, 113, 0.9)';
const secondaryMain = '#FFB500';
const secondaryMainOpacity = 'rgba(255, 181, 0, 0.5)';
const secondaryLight = '#333F48';
const tertiaryMain = '#333F48';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain
};
