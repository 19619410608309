const primaryMain = '#003057';
const primaryMainOpacity = 'rgba(0, 48, 87, 0.9)';
const secondaryMain = '#F5A800';
const secondaryMainOpacity = 'rgba(245, 168, 0, 0.5)';
const secondaryLight = '#F5A800';
const tertiaryMain = '#A2A9AD';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
