/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import compose from 'compose-function';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { reduxForm, Field, formValueSelector, getFormSyncErrors } from 'redux-form';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import InfoIcon from '@material-ui/icons/Info';

import FormProgressButton from '@shorelight/super-form/components/FormProgressButton';
import TextFieldPlus from '@shorelight/super-form/components/TextFieldPlus';
import SchoolTypography from '@shorelight/super-form/components/SchoolTypography';
import { getValidateRules } from '@shorelight/super-form/utils/SuperFormUtils';

import WelcomeInstructionList from './WelcomeInstructionList';
import { submitWelcomeForm } from '../../actions/application';

import {
  EMAIL_FIELD_ID,
  SCHEMA_FORM_NAME,
  CAPTCHA_FEATURE_FLAG_TEST,
  CAPTCHA_FEATURE_FLAG_DISABLED,
  CAPTCHA_SITE_KEY,
  AUTOMATED_CAPTCHA_TEST
} from '../../constants/constants';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white
  },
  buttonsContainer: {
    marginBottom: theme.spacing(4),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8)
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12)
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16)
    }
  },
  headerText: {
    fontSize: 17,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  },
  mainInstructionsText: {
    fontSize: 19,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  },
  additionalInstructionsText: {
    fontSize: 18,
    color: theme.palette.text.secondaryDark,
    textDecoration: 'underline',
    textAlign: 'center',
    margin: theme.spacing(3, 3, 1)
  },
  introContainer: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  instructionsContainer: {
    paddingTop: theme.spacing(3),
    fontSize: 15
  },
  formContainer: {
    backgroundColor: theme.palette.fieldset.main,
    borderTopStyle: 'dashed',
    borderTopWidth: 2,
    borderColor: theme.palette.grey[400],
    [theme.breakpoints.up('sm')]: {
      borderStyle: 'dashed',
      borderWidth: 2,
      marginBottom: theme.spacing(6)
    }
  },
  regularText: {
    fontSize: '1rem',
    color: theme.palette.text.secondary
  },
  infoIcon: {
    color: theme.palette.grey[400],
    marginLeft: theme.spacing()
  },
  supportLink: {
    color: theme.palette.text.secondary
  },
  continueMessageContainer: {
    marginTop: theme.spacing(2)
  },
  continueMessage: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '.9rem'
    }
  },
  continueButton: {
    fontSize: '1rem',
    background: theme.palette.primaryMain,
    color: theme.palette.secondaryMain
  },
  textField: {
    marginBottom: theme.spacing()
  },
  contentContainer: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 636
    }
  }
});

export class WelcomePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.refCaptcha = React.createRef();
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  validateThenSubmit = e => {
    e.preventDefault();
    // handleSubmit is called from reCAPTCHA onChange (when captcha is valid)
    // so if captcha is disabled then call directly this.handleSubmit
    if (this.refCaptcha.current) {
      this.refCaptcha.current.execute();
    } else {
      this.handleSubmit();
    }
  };

  handleSubmit = () => {
    const { handleSubmitWelcomeForm, formValues } = this.props;
    handleSubmitWelcomeForm(formValues);
    this.refCaptcha.current && this.refCaptcha.current.reset();
  };

  render() {
    const {
      classes,
      formErrors,
      inProgress,
      supportEmail,
      privacyLink,
      captchaEnabled,
      customHeader
    } = this.props;

    const continueDisabled = formErrors && Object.keys(formErrors).length > 0;
    const validateRules = getValidateRules(EMAIL_FIELD_ID, { required: true, email: true });

    const captcha_key =
      captchaEnabled !== CAPTCHA_FEATURE_FLAG_TEST ? CAPTCHA_SITE_KEY : AUTOMATED_CAPTCHA_TEST;

    return (
      <>
        {customHeader}
        <Grid container justify="center" className={classes.root}>
          <Grid item xs={12} className={classes.contentContainer}>
            <Grid container className={classes.introContainer}>
              <Grid item xs={12}>
                <SchoolTypography variant="h3" color="primaryText" className={classes.headerText}>
                  <b>A few things before you start:</b>
                </SchoolTypography>
              </Grid>
              <Grid item xs={12} container>
                <WelcomeInstructionList privacyLink={privacyLink} />
              </Grid>
            </Grid>
            <Grid item container justify="center" className={classes.formContainer}>
              <Grid item xs={12} container className={classes.instructionsContainer}>
                <Grid item xs={12} container justify="center">
                  <Typography className={classes.regularText}>
                    To start or continue an application
                  </Typography>
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Typography variant="h3" className={classes.mainInstructionsText}>
                    Enter Your Email Address
                  </Typography>
                  {this.props.isLiveSchool && (
                    <Typography variant="h4" className={classes.additionalInstructionsText}>
                      This email address will be used for your online course registration.
                    </Typography>
                  )}
                </Grid>
              </Grid>

              <form onSubmit={this.validateThenSubmit}>
                <Grid container justify="center" className={classes.buttonsContainer}>
                  {captchaEnabled !== CAPTCHA_FEATURE_FLAG_DISABLED && (
                    <ReCAPTCHA
                      ref={this.refCaptcha}
                      sitekey={captcha_key}
                      size="invisible"
                      onChange={this.handleSubmit}
                    />
                  )}
                  <Field
                    id={EMAIL_FIELD_ID}
                    label=""
                    name={EMAIL_FIELD_ID}
                    icon="email"
                    type="email"
                    autoComplete="notAllowed"
                    component={TextFieldPlus}
                    validate={validateRules}
                    placeholder="example@address.com"
                    InputProps={{
                      className: classes.textField
                    }}
                    inputProps={{
                      'aria-label': 'Primary Email'
                    }}
                  />
                  <Grid item xs={12}>
                    <FormProgressButton
                      fullWidth
                      type="submit"
                      id="continue-btn"
                      color="secondary"
                      variant="contained"
                      disabled={continueDisabled}
                      inProgress={inProgress}
                      className={classes.continueButton}
                    >
                      CONTINUE
                    </FormProgressButton>
                  </Grid>
                  <Grid container item xs={12} className={classes.continueMessageContainer}>
                    <InfoIcon className={classes.infoIcon} />

                    <Typography variant="body2" className={classes.continueMessage}>
                      If you have trouble accessing the application, please contact{' '}
                      <a className={classes.supportLink} href={'mailto:' + supportEmail}>
                        customer support.
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }
}

WelcomePage.propTypes = {
  classes: PropTypes.object.isRequired,
  captchaEnabled: PropTypes.string,
  customHeader: PropTypes.element,
  formErrors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmitWelcomeForm: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  isLiveSchool: PropTypes.bool.isRequired,
  privacyLink: PropTypes.string.isRequired,
  supportEmail: PropTypes.string.isRequired
};

const selector = formValueSelector(SCHEMA_FORM_NAME); // <-- same as form name

const mapStateToProps = state => ({
  inProgress: state.application.verifyingEmail,
  formErrors: getFormSyncErrors(SCHEMA_FORM_NAME)(state),
  formValues:
    state.schema.formids && state.schema.schema ? selector(state, ...state.schema.formids) : {},
  supportEmail: state.schema.supportEmail,
  privacyLink: state.schema.privacyLink,
  captchaEnabled: state.schema.schema && state.schema.schema.data.features.captchaEnabled
});

const mapDispatchToProps = {
  handleSubmitWelcomeForm: submitWelcomeForm
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: SCHEMA_FORM_NAME,
    destroyOnUnmount: false // <------ preserve form data
  }),
  withStyles(styles)
)(WelcomePage);
