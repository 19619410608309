export const STAGING_FORMS = false;
export const SCHEMA_FORM_NAME = 'schemaForm';
export const SIGNATURE_FORM_NAME = 'signatureForm';

export const CAPTCHA_FEATURE_FLAG_TEST = 'test';
export const CAPTCHA_FEATURE_FLAG_DISABLED = 'false';
export const CAPTCHA_SITE_KEY = '6LfAJKUUAAAAANQIoBd7K6mwk1DcU7IFHbxicaRh';
export const AUTOMATED_CAPTCHA_TEST = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const FEEDBACK_MESSAGES = {
  UPLOAD_ERROR_GENERIC: 'Something went wrong with your upload. Please wait and try again.',
  UPLOAD_ERROR_TYPE: 'Please upload only a .jpg, .png, .pdf, or .docx file.',
  UPLOAD_ERROR_SIZE: 'Please do not upload any files larger than 25 MB',
  BULK_UPLOAD_ERROR_SIZE: 'One or more of your files are too large to be uploaded.'
};

// THESE ARE FORM FIELD IDS CAN BE CHANGED BY SCHEMA RETURNED BY THE SERVICE
export const FIRST_NAME_FIELD_ID = 'firstName';
export const LAST_NAME_FIELD_ID = 'lastName';
export const EMAIL_FIELD_ID = 'primaryEmail';
export const ADDRESS_COUNTRY = 'addressCountry';
export const FILE_UPLOAD_FIELD_ID = 'transcriptUpload';

export const REQUIRED_FIELDS_FOR_LEAD = [FIRST_NAME_FIELD_ID, LAST_NAME_FIELD_ID, EMAIL_FIELD_ID];

export const INVALID_VERIFICATION_CODE_ERROR = '401';
export const EXPIRED_VERIFICATION_CODE_ERROR = '403';
export const KNOW_VERIFICATION_CODE_ERRORS = [
  INVALID_VERIFICATION_CODE_ERROR,
  EXPIRED_VERIFICATION_CODE_ERROR
];

//As part of RE-1510, HARD_STOP_APPLICATION_SCHOOLS was modified not to check Portal Source against ACLive, to allow all student to apply
//TO-DO: come back to this once Admissions Portal supports AC Live applications
// export const HARD_STOP_APPLICATION_SCHOOLS = ['ACLive-UMB'];
export const HARD_STOP_APPLICATION_SCHOOLS = [];
export const LIVE_SCHOOL_SUFFIX = '-live';
export const APPLICANT_SOURCE_ADMISSIONS_PORTAL = 'Portal Application';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const GENERIC_REDIRECT_URL = 'https://shorelight.com/';
