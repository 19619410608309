const primaryMain = '#A71930';
const primaryMainOpacity = 'rgba(167, 25, 48, 0.9)';
const secondaryMain = '#1E1E1E';
const secondaryMainOpacity = 'rgba(30, 30, 30, 0.5)';
const secondaryLight = '#0094B3';
const tertiaryMain = '#1E1E1E';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
