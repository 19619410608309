import { $black } from './commonColors';

const primaryMain = '#A4343A';
const primaryMainOpacity = 'rgba(164, 52, 58, 0.9)';
const secondaryMain = $black;
const secondaryMainOpacity = 'rgba(0, 0, 0, 0.5)';
const secondaryLight = $black;
const tertiaryMain = $black;

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
