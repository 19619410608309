//this is middleware for mapping GA events to redux actions

import { actionTypes } from 'redux-form';
import {
  GO_TO_PAGE_APPLICATION,
  SET_UID,
  REVIEW_APPLICATION,
  FOUND_EXISTING_CONTACT,
  VERIFY_CODE_SUCCESS,
  VALIDATE_FORM_FAIL,
  CONTINUE_APPLICATION_REQUEST_SUCCESS,
  SUBMIT_APPLICATION_SUCCESS,
  REQUIRE_EMAIL_VERIFICATION,
  SET_INITIAL_EMAIL,
  WELCOME_PAGE_SUBMIT_SUCCESS,
  BLOCKIN_DIALOG_SHOWED
} from '../actions/application.constants';

import {
  SCHEMA_FORM_NAME,
  SIGNATURE_FORM_NAME,
  EMAIL_FIELD_ID,
  FIRST_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID
} from '../constants/constants';
import {
  FILE_UPLOAD_START,
  SELECT_FILES,
  REMOVE_FILE,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL
} from '../actions/upload.constants';

export const GA_VISIT_PAGE = 'VISIT_PAGE';
export const GA_FORM_FOCUS = 'FORM_FOCUS';
export const GA_FORM_BLUR = 'FORM_BLUR';
export const GA_USER_REDIRECT = 'USER_BLOCKED_REDIRECT';

const getVWOVirtualPageUrl = (state, action) => {
  const { href, search } = window.location;
  const appender = search ? '&' : '?';
  switch (action.type) {
    case SET_UID:
      return `${href}${appender}page=${state.application.pageNumber}`;
    case GO_TO_PAGE_APPLICATION:
      return `${href}${appender}page=${action.pageNumber}`;
    case REVIEW_APPLICATION:
      return `${href}${appender}page=${SIGNATURE_FORM_NAME}`;
    default:
      return '';
  }
};

const analyticsMiddleware = store => next => action => {
  if (window.dataLayer) {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case GO_TO_PAGE_APPLICATION: {
        window.dataLayer.push({ event: GA_VISIT_PAGE, pageNumber: action.pageNumber + 1 });
        break;
      }
      case actionTypes.FOCUS: {
        window.dataLayer.push({ event: GA_FORM_FOCUS, fieldName: action.meta.field });
        break;
      }
      case actionTypes.BLUR: {
        window.dataLayer.push({ event: GA_FORM_BLUR, fieldValue: action.payload });
        break;
      }
      case FOUND_EXISTING_CONTACT: {
        window.dataLayer.push({ event: action.type, details: action.additionalDetails });
        break;
      }
      case SUBMIT_APPLICATION_SUCCESS: {
        window.dataLayer.push({ event: action.type, values: store.getState().application.values });
        break;
      }
      case SET_INITIAL_EMAIL: {
        window.dataLayer.push({ event: action.type, value: action.value });
        break;
      }
      case BLOCKIN_DIALOG_SHOWED: {
        const { form } = store.getState();
        const { values } = form[SCHEMA_FORM_NAME];

        const email = values[EMAIL_FIELD_ID];
        const firstName = values[FIRST_NAME_FIELD_ID];
        const lastName = values[LAST_NAME_FIELD_ID];

        window.dataLayer.push({ event: GA_USER_REDIRECT, email, firstName, lastName });
        break;
      }
      case WELCOME_PAGE_SUBMIT_SUCCESS:
      case REVIEW_APPLICATION:
      case VERIFY_CODE_SUCCESS:
      case VALIDATE_FORM_FAIL:
      case REQUIRE_EMAIL_VERIFICATION:
      case CONTINUE_APPLICATION_REQUEST_SUCCESS:
      case SELECT_FILES:
      case REMOVE_FILE:
      case FILE_UPLOAD_START:
      case FILE_UPLOAD_SUCCESS:
      case FILE_UPLOAD_FAIL: {
        window.dataLayer.push({ event: action.type });
        break;
      }
    }
  }

  if (window.VWO) {
    switch (action.type) {
      case SET_UID:
      case REVIEW_APPLICATION:
      case GO_TO_PAGE_APPLICATION:
        window.VWO.push([
          'activate',
          { virtualPageUrl: getVWOVirtualPageUrl(store.getState(), action) }
        ]);
        break;
      default:
        break;
    }
  }
  return next(action);
};
export default analyticsMiddleware;
