const primaryMain = '#004B8D';
const primaryMainOpacity = 'rgba(0, 75, 141, 0.9)';
const secondaryMain = '#FDB813';
const secondaryMainOpacity = 'rgba(253, 184, 19, 0.5)';
const secondaryLight = '#FAECCD';
const tertiaryMain = '#173C6D';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
