const primaryMain = '#007741';
const primaryMainOpacity = 'rgba(0, 119, 65, 0.9)';
const secondaryMain = '#afd9a1';
const secondaryMainOpacity = 'rgba(175, 217, 161, 0.5)';
const secondaryLight = '#61b853';
const tertiaryMain = '#AFD9A1';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
