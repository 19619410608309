/**
 * Created by jcamera on 6/11/17.
 */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import analyticsMiddleware from './analyticsMiddleware';

const middlewares = [thunk, analyticsMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

export default function configureStore(preloadedState) {
  return createStore(rootReducer, preloadedState, applyMiddleware(...middlewares));
}
