export const $black = '#000000';
export const $blackOpacity = 'rgba(0, 0, 0, 0.87)';
export const $white = '#FFFFFF';
export const $whiteOpacity = 'rgba(255,255,255,0.4)';
export const $greyDark = '#434347';
export const $greyLight = '#C1C5CC';
export const $greyExtraLight = '#E6E6E6';
export const $shorelightBlue = '#212E36';

export const $spinnerColor = '#5D5D5D';
export const $succesBarColor = 'rgba(245, 245, 245, 0.9)';
export const $successMain = '#5DAB86';
export const $errorMain = '#B40400';
export const $errorLight = '#FFF6F6';

export const $disabled = '#5E5E5E';
export const $disabledBackground = '#CCCCCC';
export const $disabledDark = '#EDEDED';

export const $fieldColor = '#5E5E5E';
export const $fieldsetBackground = '#F7F7F3';
export const $fieldsetBackgroundDark = '#F3F2EE';

export const $inputBorder = 'rgba(0, 0, 0, 0.23)';
