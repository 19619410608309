const primaryMain = '#21543F';
const primaryMainOpacity = 'rgba(33, 84, 63, 0.9)';
const secondaryMain = '#71C5E8';
const secondaryMainOpacity = 'rgba(113, 197, 232, 0.5)';
const secondaryLight = '#00558C';
const tertiaryMain = '#D0DEBB';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
