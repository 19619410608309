import { getQueryParams } from '../utils/forms';
import { STAGING_FORMS, FILE_UPLOAD_FIELD_ID } from '../constants/constants';

import { setUIDDataobj, createPageProgressMap, setQueryParams } from './application';
import {
  parseObjectToQueryString,
  getFieldIds,
  parseExistingFiles,
  redirectElsewhere
} from './actionUtils';

import { Logz } from './configureLogz';

import { SCHEMA_SET, SCHEMA_REQUEST, SCHEMA_REQUEST_ERROR } from './formSchema.constants';

export function loadingSchema() {
  return {
    type: SCHEMA_REQUEST
  };
}

export function setSchema(schema, formids) {
  return {
    type: SCHEMA_SET,
    schema,
    formids
  };
}

export function schemaRequestError() {
  return {
    type: SCHEMA_REQUEST_ERROR
  };
}

export function initApplication() {
  return dispatch => {
    const params = getQueryParams();

    if (Object.keys(params).length < 2) {
      //if not enough params, fallback to generic site
      return redirectElsewhere();
    }

    //for Google Tag Manager
    if (window.dataLayer) {
      window.dataLayer.push({
        school: params.cfm_school,
        applicationType: params.cfm_level
      });
    }

    dispatch(setQueryParams(params));

    return dispatch(fetchSchema()).catch(() => {
      //if fetching the schema fails, fallback to generic site
      redirectElsewhere();
    });
  };
}

export function fetchSchema(uid) {
  return (dispatch, getState) => {
    //There is a uid stored from a previous form
    const givenUID = uid;

    const queryStringParams = {
      type: 'admissions',
      ...(givenUID && {
        uid: givenUID
      }),
      ...getState().application.params,
      ...(process.env.NODE_ENV === 'development' && { staging: STAGING_FORMS })
    };

    //convert to string
    const parsedParamString = parseObjectToQueryString(queryStringParams);

    const url = `${process.env.REACT_APP_BASE_URL}/formsna?${parsedParamString}`;

    dispatch(loadingSchema());

    return fetch(url, { credentials: 'same-origin' })
      .then(response => {
        if (!response.ok) throw response;
        return response.json();
      })
      .then(form => {
        dispatch(createPageProgressMap());
        return form;
      })
      .then(form => {
        dispatch(setSchema(form, getFieldIds(form)));
        return form;
      })
      .then(form => {
        const { data } = form;
        dispatch(setUIDDataobj(data));
      })
      .catch(error => {
        dispatch(schemaRequestError());
        Logz.error(`fetchSchema Error ${error.message}`);
        //return a rejected promise so it can be chained outside
        return Promise.reject(error);
      });
  };
}

export default function reducer(state = { submitSuccess: false }, action) {
  switch (action.type) {
    case SCHEMA_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case SCHEMA_SET:
      const {
        data: { schoolUrl, supportEmail, privacyLink, existingFiles = '', ...otherData },
        ...otherSchema
      } = action.schema;

      const parsedFiles = parseExistingFiles(existingFiles);

      return {
        ...state,
        schema: {
          ...otherSchema,
          data: {
            ...otherData,
            //we set the value of the file uploader as the value of the parsed array
            [FILE_UPLOAD_FIELD_ID]: parsedFiles
          }
        },
        formids: action.formids,
        isFetching: false,
        schoolUrl,
        supportEmail,
        privacyLink
      };
    case SCHEMA_REQUEST_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false
      };
    }
    default:
      return state;
  }
}
