const primaryMain = '#651D32';
const primaryMainOpacity = 'rgba(101, 29, 50, 0.9)';
const secondaryMain = '#C39439';
const secondaryMainOpacity = 'rgba(195, 148, 57, 0.5)';
const secondaryLight = '#00B3DC';
const tertiaryMain = '#8C8C8C';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
