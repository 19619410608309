const primaryMain = '#F26722';
const primaryMainOpacity = 'rgba(242, 103, 34, 0.9)';
const secondaryMain = '#002B5C';
const secondaryMainOpacity = 'rgba(0, 43, 92, 0.5)';
const secondaryLight = '#B83433';
const tertiaryMain = '#002B5C';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
