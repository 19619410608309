const primaryMain = '#232C99';
const primaryMainOpacity = 'rgba(35, 44, 153, 0.9)';
const secondaryMain = '#06233D';
const secondaryMainOpacity = 'rgba(6, 35, 61, 0.5)';
const secondaryLight = '#0160F2';
const tertiaryMain = '#06233D';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
