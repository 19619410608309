import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import compose from 'compose-function';
import { connect } from 'react-redux';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

import SuperFormContainer from '../SuperFormComponents/SuperFormContainer';

import ConsentDialog from '../Dialogs/ConsentDialog';
import VerifyDialog from '../Dialogs/VerifyDialog/VerifyDialog';
import SameYearTermErrorDialog from '../Dialogs/SameYearTermErrorDialog';

import { initApplication } from '../../actions/formSchema';
import { resetTermAndYearError } from '../../actions/application';

import { buildTheme } from '../../themes/themeBuilder';

const styles = {
  fetching: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
};

export class RootContainer extends PureComponent {
  componentDidMount() {
    this.props.initApplication();
  }

  render() {
    const {
      currentForm,
      classes,
      consentDialogOpen,
      sameYearTermError,
      resetTermAndYearError,
      theme
    } = this.props;

    return !currentForm ? (
      <div className={classes.fetching}>
        <CircularProgress size={60} />
      </div>
    ) : (
      <MuiThemeProvider theme={theme}>
        <SuperFormContainer />
        <VerifyDialog />
        {consentDialogOpen && <ConsentDialog />}
        {sameYearTermError && <SameYearTermErrorDialog onCloseDialog={resetTermAndYearError} />}
      </MuiThemeProvider>
    );
  }
}

RootContainer.propTypes = {
  initApplication: PropTypes.func.isRequired,
  resetTermAndYearError: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  consentDialogOpen: PropTypes.bool,
  sameYearTermError: PropTypes.bool,
  currentForm: PropTypes.object,
  theme: PropTypes.object
};

RootContainer.defaultProps = {
  theme: {},
  consentDialogOpen: false,
  sameYearTermError: false
};

// theme is defined outside so it is ONLY created the first time data.school has a value
// (it is memoized)
let theme;

function mapStateToProps(state) {
  const data = state.schema.schema ? state.schema.schema.data : {};
  theme = !theme ? buildTheme(data.school) : theme;

  return {
    currentForm: state.schema.schema,
    consentDialogOpen: state.application.consentDialogOpen,
    sameYearTermError: state.application.sameYearTermError,
    theme
  };
}

const mapDispatchToProps = {
  initApplication,
  resetTermAndYearError
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(RootContainer);
