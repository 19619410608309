const primaryMain = '#470A68';
const primaryMainOpacity = 'rgba(71, 10, 104, 0.9)';
const secondaryMain = '#C61786';
const secondaryMainOpacity = 'rgba(198, 23, 134, 0.5)';
const secondaryLight = '#2F1748';
const tertiaryMain = '#2F1748';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
