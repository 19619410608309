import Log from '@shorelight/fat-logs';

const logConfig = {
  batchLoggerInterval: 10000,
  appName: 'forms-client',
  logEndpoint: 'https://logs.shorelight.com/v1/logstream/',
  localLogging: true
};

Log.initialize(logConfig);

export { Log as Logz };
