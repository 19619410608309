const primaryMain = '#490F75';
const primaryMainOpacity = 'rgba(73, 15, 117, 0.9)';
const secondaryMain = '#F7AF1A';
const secondaryMainOpacity = 'rgba(247, 175, 26, 0.5)';
const secondaryLight = '#290043';
const tertiaryMain = '#290043';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
