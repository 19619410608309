const primaryMain = '#1170A0';
const primaryMainOpacity = 'rgba(17, 112, 160, 0.9)';
const secondaryMain = '#F1B636';
const secondaryMainOpacity = 'rgba(241, 182, 54, 0.5)';
const secondaryLight = '#0D1E32';
const tertiaryMain = '#0D1E32';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
