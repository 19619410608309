const primaryMain = '#8B2331';
const primaryMainOpacity = 'rgba(139, 35, 49, 0.9)';
const secondaryMain = '#FDB813';
const secondaryMainOpacity = 'rgba(253, 184, 19, 0.5)';
const secondaryLight = '#40B4E5';
const tertiaryMain = '#646469';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
