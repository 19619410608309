export const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS';
export const SUBMIT_APPLICATION_START = 'SUBMIT_APPLICATION_START';
export const SUBMIT_APPLICATION_FAIL = 'SUBMIT_APPLICATION_FAIL';
export const PARTIAL_SAVE_SUCCESS = 'PARTIAL_SAVE_SUCCESS';
export const PARTIAL_SAVE_START = 'PARTIAL_SAVE_START';
export const PARTIAL_SAVE_FAIL = 'PARTIAL_SAVE_FAIL';

export const VALIDATE_FORM_SUCCESS = 'VALIDATE_FORM_SUCCESS';
export const VALIDATE_FORM_FAIL = 'VALIDATE_FORM_FAIL';

export const REQUIRE_EMAIL_VERIFICATION = 'REQUIRE_EMAIL_VERIFICATION';
export const CANCEL_EMAIL_VERIFICATION = 'CANCEL_EMAIL_VERIFICATION';
export const GENERATE_VERIFICATION_CODE_SUCCESS = 'GENERATE_VERIFICATION_CODE_SUCCESS';
export const GENERATE_VERIFICATION_CODE_START = 'GENERATE_VERIFICATION_CODE_START';
export const GENERATE_VERIFICATION_CODE_FAIL = 'GENERATE_VERIFICATION_CODE_FAIL';
export const VERIFY_CODE_SUCCESS = 'VERIFY_CODE_SUCCESS';
export const VERIFY_CODE_START = 'VERIFY_CODE_START';
export const VERIFY_CODE_FAIL = 'VERIFY_CODE_FAIL';
export const VERIFYING_EMAIL = 'VERIFYING_EMAIL';

export const CONTINUE_APPLICATION_REQUEST_START = 'CONTINUE_APPLICATION_REQUEST_START';
export const CONTINUE_APPLICATION_REQUEST_SUCCESS = 'CONTINUE_APPLICATION_REQUEST_SUCCESS';

export const SET_UID = 'SET_UID';

export const CREATE_PAGE_PROGRESS_MAP = 'CREATE_PAGE_PROGRESS_MAP';
export const GO_TO_PAGE_APPLICATION = 'GO_TO_PAGE_APPLICATION';
export const UPDATE_PAGE_PROGRESS = 'UPDATE_PAGE_PROGRESS';
export const REVIEW_APPLICATION = 'REVIEW_APPLICATION';

export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';
export const FOUND_EXISTING_CONTACT = 'FOUND_EXISTING_CONTACT';
export const SAME_YEAR_TERM_SCHOOL_ERROR = 'SAME_YEAR_TERM_SCHOOL_ERROR';
export const CLEAR_TERM_AND_YEAR_ERROR = 'CLEAR_TERM_AND_YEAR_ERROR';

export const DUPLICATED_EMAIL_ERROR_RESPONSE_CODE = 409;
export const EXISTING_CONTACT_RESPONSE_CODE = 101004;
export const EXISTING_LEAD_RESPONSE_CODE = '101012';
export const EXISTING_TERM_YEAR_SCHOOL_RESPONSE_CODE = 101013;

export const OPEN_CONSENT_DIALOG = 'OPEN_CONSENT_DIALOG';
export const CLOSE_CONSENT_DIALOG = 'CLOSE_CONSENT_DIALOG';
export const ACCEPT_CONSENT = 'ACCEPT_CONSENT';
export const BLOCKIN_DIALOG_SHOWED = 'BLOCKIN_DIALOG_SHOWED';

export const SET_INITIAL_EMAIL = 'SET_INITIAL_EMAIL';

export const WELCOME_PAGE_SUBMIT_SUCCESS = 'WELCOME_PAGE_SUBMIT_SUCCESS';
