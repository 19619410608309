const primaryMain = '#03244D';
const primaryMainOpacity = 'rgba(0, 50, 99, 0.9)';
const secondaryMain = '#CC4E0B';
const secondaryMainOpacity = 'rgba(235,115,4, 0.5)';
const secondaryLight = '#F4822B';
const tertiaryMain = '#003263';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  logoContainer: {
    height: 50,
    marginTop: 12
  }
};
