const primaryMain = '#560C20';
const primaryMainOpacity = 'rgba(86, 12, 32, 0.9)';
const secondaryMain = '#9F6C02';
const secondaryMainOpacity = 'rgba(159, 108, 2, 0.5)';
const secondaryLight = '#09667C';
const tertiaryMain = '#3C1921';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
