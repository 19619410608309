const primaryMain = '#0c2340';
const primaryMainOpacity = 'rgba(12, 35, 64, 0.9)';
const secondaryMain = '#9c1c31';
const secondaryMainOpacity = 'rgba(156, 28, 49, 0.5)';
const secondaryLight = '#b6862d';
const tertiaryMain = '#C2D1D3';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};