const primaryMain = '#0051BA';
const primaryMainOpacity = 'rgba(0, 81, 186, 0.9)';
const secondaryMain = '#E8000D';
const secondaryMainOpacity = 'rgba(232, 0, 13, 0.5)';
const secondaryLight = '#FFC82D';
const tertiaryMain = '#004399';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
