const blue = '#001E62';

const primaryMain = '#007FA5';
const primaryMainOpacity = 'rgba(0, 127, 165, 0.9)';
const secondaryMain = '#D50032';
const secondaryMainOpacity = 'rgba(213, 0, 50, 0.5)';
const secondaryLight = blue;
const tertiaryMain = blue;

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
