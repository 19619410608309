const primaryMain = '#0F2846';
const primaryMainOpacity = 'rgba(15, 40, 70, 0.9)';
const secondaryMain = '#C4122E';
const secondaryMainOpacity = 'rgba(196, 18, 46, 0.5)';
const secondaryLight = '#EEB137';
const tertiaryMain = '#163A65';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  logoContainer: {
    height: 40,
    marginTop: 20
  },
  logoContainerMobile: {
    height: 30,
    marginBottom: 20
  }
};
