const primaryMain = '#0C2340';
const primaryMainOpacity = 'rgba(12, 35, 64, 0.9)';
const secondaryMain = '#F15A22';
const secondaryMainOpacity = 'rgba(241, 90, 34, 0.5)';
const secondaryLight = '#D3430D';
const tertiaryMain = '#495A70';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
