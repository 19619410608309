const primaryMain = '#005D93';
const primaryMainOpacity = 'rgba(0, 93, 147, 0.9)';
const secondaryMain = '#003359';
const secondaryMainOpacity = 'rgba(0, 51, 89, 0.5)';
const secondaryLight = '#CE4422';
const tertiaryMain = '#003359';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
