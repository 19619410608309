const getIcon = schoolCode => {
  switch (schoolCode) {
    case 'ACL-UMB':
      return 'ACLA.ico';
    case 'CSU-live':
      return 'CSU.ico';
    case 'DAY-live':
      return 'DAY.ico';
    case 'UMA-live':
      return 'UMA.ico';
    case 'USC-live':
      return 'USC.ico';
    case 'STF-live':
    case 'UCB-live':
      return 'shorelight.ico';
    default:
      return `${schoolCode}.ico`;
  }
};

export const changeFavicon = schoolCode => {
  const link = document.createElement('link');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = `${process.env.PUBLIC_URL}/favicons/${getIcon(schoolCode)}`;

  (document.head || document.getElementsByTagName('head')[0]).appendChild(link);
};
