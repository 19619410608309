const primaryMain = '#03FCFC';
const primaryMainOpacity = '#03FCFC';
const primaryDark = '#03FCFC';
const secondaryMain = '#03FCFC';
const secondaryMainOpacity = '#03FCFC';
const secondaryLight = '#03FCFC';
const tertiaryMain = '#03FCFC';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryDark,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  tertiaryMain,
  secondaryText: secondaryMain
};
