const primaryMain = '#461C7C';
const primaryMainOpacity = 'rgba(70, 28, 124, 0.9)';
const secondaryMain = '#FDD023';
const secondaryMainOpacity = 'rgba(253, 208, 35, 0.5)';
const secondaryLight = '#D29F13';
const tertiaryMain = '#613D8F';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: primaryMain,
  tertiaryMain,
  logoContainer: {
    height: 40,
    marginTop: 20
  },
  logoContainerMobile: {
    height: 30,
    marginBottom: 20
  },
  headerPaddingTop: 28,
  headerPaddingBottom: 28
};
