const primaryMain = '#990022';
const primaryMainOpacity = 'rgba(153, 0, 34, 0.9)';
const secondaryMain = '#8D724B';
const secondaryMainOpacity = 'rgba(141, 114, 75, 0.5)';
const secondaryLight = '#36424E';
const tertiaryMain = '#6B011E';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
