const primaryMain = '#142142';
const primaryMainOpacity = 'rgba(0, 50, 99, 0.9)';
const secondaryMain = '#E31936';
const secondaryMainOpacity = 'rgba(227, 25, 54, 0.5)';
const secondaryLight = '#CE1126';
const tertiaryMain = '#252B5D';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain
};
