import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import ICPLicense from '@shorelight/ui-common-components/cjs/components/icpLicense/ICPLicense';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  licenseContainer: {
    fontSize: '.815rem',
    height: 200,
    marginTop: theme.spacing(),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      height: 120,
      paddingLeft: theme.spacing(4)
    }
  },
  lowHeight: {
    height: 40
  },
  success: {
    marginTop: 0,
    backgroundColor: theme.palette.fieldset.main,
    paddingBottom: theme.spacing(4)
  }
});

export const LicenseFooter = ({ classes, withLowHeight = false, withSuccesStyle = false }) => {
  return (
    <ICPLicense
      host={process.env.REACT_APP_BASE_URL}
      containerClass={classnames(classes.licenseContainer, {
        [classes.lowHeight]: withLowHeight,
        [classes.success]: withSuccesStyle
      })}
    />
  );
};

LicenseFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  withLowHeight: PropTypes.bool,
  withSuccesStyle: PropTypes.bool
};

export default withStyles(styles)(LicenseFooter);
