import { combineReducers } from 'redux';
import { reducer as reduxForms } from 'redux-form';

import formSchema from './formSchema';
import application from './application';
import upload from './upload';

export default combineReducers({
  schema: formSchema,
  application: application,
  upload: upload,
  form: reduxForms
});
