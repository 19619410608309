import React from 'react';
import compose from 'compose-function';
import PropTypes from 'prop-types';

import IconLock from '@material-ui/icons/Lock';
import IconSave from '@material-ui/icons/Save';
import IconInput from '@material-ui/icons/Input';
import IconAccessTime from '@material-ui/icons/AccessTime';
import IconPhotoCamera from '@material-ui/icons/PhotoCamera';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import withIsMobile from '@shorelight/super-form/components/withIsMobile';

import WelcomeInstructionItem from './WelcomeInstructionItem';

const styles = theme => ({
  listText: {
    fontSize: '1rem',
    color: theme.palette.text.secondary
  },
  link: {
    color: theme.palette.text.secondaryText
  }
});

export class WelcomeInstructionList extends React.PureComponent {
  render() {
    const { classes, isMobile, privacyLink } = this.props;
    return (
      <List>
        <WelcomeInstructionItem
          Icon={IconAccessTime}
          primaryText={
            <Typography component="span" className={classes.listText}>
              It will take approximately <strong>5-10 minutes</strong> to complete the application.
            </Typography>
          }
        />
        <WelcomeInstructionItem
          Icon={IconLock}
          primaryText={
            <Typography component="span" className={classes.listText} color="textPrimary">
              All of the personal information you enter will remain{' '}
              <strong>secure and private</strong>. To learn how we protect your information, review
              our{' '}
              <a
                href={privacyLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                privacy policy
              </a>
              .
            </Typography>
          }
        />
        <WelcomeInstructionItem
          Icon={IconSave}
          primaryText={
            <Typography component="span" className={classes.listText} color="textPrimary">
              Your information is <strong>automatically saved</strong> as you complete each step.
            </Typography>
          }
        />
        <WelcomeInstructionItem
          Icon={IconInput}
          primaryText={
            <Typography component="span" className={classes.listText} color="textPrimary">
              You can <strong>exit and resume the application</strong> at a later time, by entering
              your email address below and submitting the <strong>verification code</strong> when
              prompted.
            </Typography>
          }
        />
        {isMobile && (
          <WelcomeInstructionItem
            Icon={IconPhotoCamera}
            primaryText={
              <Typography component="span" className={classes.listText} color="textPrimary">
                You can use your <strong>phone's camera</strong> to scan or take pictures of your
                documents if you're ready to upload them.
              </Typography>
            }
          />
        )}
      </List>
    );
  }
}

WelcomeInstructionList.propTypes = {
  privacyLink: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default compose(withStyles(styles), withIsMobile())(WelcomeInstructionList);
