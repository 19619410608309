const primaryMain = '#002E62';
const primaryMainOpacity = 'rgba(0, 46, 98, 0.9)';
const secondaryMain = '#98012E';
const secondaryMainOpacity = 'rgba(152, 1, 46, 0.5)';
const secondaryLight = '#0F70B3';
const tertiaryMain = '#011F42';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
