const primaryMain = '#00205B';
const primaryMainOpacity = 'rgba(0, 32, 91, 0.9)';
const secondaryMain = '#C8102E';
const secondaryMainOpacity = 'rgba(200, 16, 46, 0.5)';
const secondaryLight = '#5699CD';
const tertiaryMain = '#00103C';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
