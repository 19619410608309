const primaryMain = '#CC0033';
const primaryMainOpacity = 'rgba(204, 0, 51, 0.9)';
const secondaryMain = '#5F6A72';
const secondaryMainOpacity = 'rgba(95, 106, 114, 0.5)';
const secondaryLight = '#00626D';
const tertiaryMain = '#000000';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
