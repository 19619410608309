import { touch, destroy, clearFields } from 'redux-form';

import { parseObjectToQueryString, redirectElsewhere, getTermYearForSchool } from './actionUtils';
import { fetchSchema } from './formSchema';
import { Logz } from './configureLogz';
import {
  STAGING_FORMS,
  SCHEMA_FORM_NAME,
  EMAIL_FIELD_ID,
  INVALID_VERIFICATION_CODE_ERROR,
  EXPIRED_VERIFICATION_CODE_ERROR,
  KNOW_VERIFICATION_CODE_ERRORS
} from '../constants/constants';

import {
  SUBMIT_APPLICATION_SUCCESS,
  SUBMIT_APPLICATION_START,
  SUBMIT_APPLICATION_FAIL,
  PARTIAL_SAVE_SUCCESS,
  PARTIAL_SAVE_START,
  PARTIAL_SAVE_FAIL,
  VALIDATE_FORM_SUCCESS,
  VALIDATE_FORM_FAIL,
  REQUIRE_EMAIL_VERIFICATION,
  CANCEL_EMAIL_VERIFICATION,
  GENERATE_VERIFICATION_CODE_SUCCESS,
  GENERATE_VERIFICATION_CODE_START,
  GENERATE_VERIFICATION_CODE_FAIL,
  VERIFY_CODE_SUCCESS,
  VERIFY_CODE_START,
  VERIFY_CODE_FAIL,
  CONTINUE_APPLICATION_REQUEST_START,
  CONTINUE_APPLICATION_REQUEST_SUCCESS,
  SET_UID,
  CREATE_PAGE_PROGRESS_MAP,
  GO_TO_PAGE_APPLICATION,
  UPDATE_PAGE_PROGRESS,
  REVIEW_APPLICATION,
  SET_QUERY_PARAMS,
  FOUND_EXISTING_CONTACT,
  SAME_YEAR_TERM_SCHOOL_ERROR,
  DUPLICATED_EMAIL_ERROR_RESPONSE_CODE,
  EXISTING_CONTACT_RESPONSE_CODE,
  EXISTING_LEAD_RESPONSE_CODE,
  EXISTING_TERM_YEAR_SCHOOL_RESPONSE_CODE,
  CLEAR_TERM_AND_YEAR_ERROR,
  OPEN_CONSENT_DIALOG,
  CLOSE_CONSENT_DIALOG,
  ACCEPT_CONSENT,
  VERIFYING_EMAIL,
  SET_INITIAL_EMAIL,
  WELCOME_PAGE_SUBMIT_SUCCESS,
  BLOCKIN_DIALOG_SHOWED
} from './application.constants.js';

const removeDataObjs = data => {
  return Object.keys(data).reduce((acc, key) => {
    if (!key.startsWith('dataobj_')) {
      acc[key] = data[key];
    }
    return acc;
  }, {});
};

const mergeSubmissionValues = (values, applicationData) => {
  const valuesWithoutDataObjs = removeDataObjs(values);

  // Elements are returned in a specific order below, to be able to handle 2 scenarios:
  // 1. First partial save: applicationData has all key-value pairs that were retrieved when fetching the form and if updates were made before triggering it,
  // those updates are in values so first add applicationData and then add values.
  // For this case, filtering out the data object elements from values is irrelevant, but that's needed for the next scenario.
  // 2. Subsequent partial saves: applicationData only has the elements with keys dataobj_x, uid and uuid.
  // The order in which applicationData and values are added is not relevant anymore, but we need to make sure the elements in applicationData replace the ones in values,
  // which is why we filtered out the data object elements from values above and explicitly set ui and uuid from applicationData.
  return {
    ...applicationData,
    ...valuesWithoutDataObjs,
    uid: applicationData.uid,
    uuid: applicationData.uuid
  };
};

const requestFormSubmission = (values, otherUrlParametersAsObject = {}) => {
  const otherParamsAsString = parseObjectToQueryString({
    ...otherUrlParametersAsObject,
    ...(process.env.NODE_ENV === 'development' && { staging: STAGING_FORMS })
  });

  const url = `${process.env.REACT_APP_BASE_URL}/formsna?${otherParamsAsString}`;

  const config = {
    method: 'POST',
    body: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return fetch(url, config).then(response => {
    if (!response.ok) {
      return response.json().then(responseContent => Promise.reject(responseContent));
    }
    return response.json();
  });
};

function submitApplicationStart() {
  return {
    type: SUBMIT_APPLICATION_START
  };
}

function submitApplicationSuccess(error) {
  return {
    type: SUBMIT_APPLICATION_SUCCESS
  };
}

function submitApplicationFail() {
  return {
    type: SUBMIT_APPLICATION_FAIL
  };
}

function partialSaveStart() {
  return {
    type: PARTIAL_SAVE_START
  };
}

function partialSaveSuccess(applicationData, values) {
  return {
    type: PARTIAL_SAVE_SUCCESS,
    applicationData,
    values
  };
}

function partialSaveFail() {
  return {
    type: PARTIAL_SAVE_FAIL
  };
}

function navigateToPageApplication(pageNumber) {
  return {
    type: GO_TO_PAGE_APPLICATION,
    pageNumber
  };
}

function validateFormSuccess(applicationData) {
  return {
    type: VALIDATE_FORM_SUCCESS,
    applicationData
  };
}

function requireEmailVerification(additionalDetails) {
  return {
    type: REQUIRE_EMAIL_VERIFICATION,
    additionalDetails
  };
}

function existingTermYearSchoolError() {
  return {
    type: SAME_YEAR_TERM_SCHOOL_ERROR
  };
}

function validateFormFail() {
  return {
    type: VALIDATE_FORM_FAIL
  };
}

function generateVerificationCodeStart() {
  return {
    type: GENERATE_VERIFICATION_CODE_START
  };
}

function generateVerificationCodeSuccess() {
  return {
    type: GENERATE_VERIFICATION_CODE_SUCCESS
  };
}

function generateVerificationCodeFail() {
  return {
    type: GENERATE_VERIFICATION_CODE_FAIL
  };
}

function verifyCodeStart() {
  return {
    type: VERIFY_CODE_START
  };
}

function verifyCodeSuccess(applicationData) {
  return {
    type: VERIFY_CODE_SUCCESS,
    applicationData
  };
}

function verifyCodeFail(errorObject) {
  return {
    type: VERIFY_CODE_FAIL,
    errorCode: errorObject.message
  };
}

function continueApplicationRequestStart() {
  return {
    type: CONTINUE_APPLICATION_REQUEST_START
  };
}

function continueApplicationRequestSuccess() {
  return {
    type: CONTINUE_APPLICATION_REQUEST_SUCCESS
  };
}

function clearTermAndYearError() {
  return {
    type: CLEAR_TERM_AND_YEAR_ERROR
  };
}

export function setQueryParams(params) {
  return {
    type: SET_QUERY_PARAMS,
    params
  };
}

function foundExistingContact(additionalDetails) {
  return {
    type: FOUND_EXISTING_CONTACT,
    additionalDetails
  };
}

export function openConsentDialog() {
  return {
    type: OPEN_CONSENT_DIALOG
  };
}

export function closeConsentDialog() {
  return {
    type: CLOSE_CONSENT_DIALOG
  };
}

export function acceptConsent() {
  return {
    type: ACCEPT_CONSENT
  };
}

export function verifyingEmail() {
  return {
    type: VERIFYING_EMAIL
  };
}

export function setInitialEmail(value) {
  return {
    type: SET_INITIAL_EMAIL,
    value
  };
}

export function welcomePageSubmitSuccess(value) {
  return {
    type: WELCOME_PAGE_SUBMIT_SUCCESS
  };
}

export function resetTermAndYearError() {
  return (dispatch, getState) => {
    const termYearId = getTermYearForSchool(getState());

    dispatch(clearFields(SCHEMA_FORM_NAME, true, true, termYearId));
    dispatch(clearTermAndYearError());
  };
}

/**
 * Function for asynchronous email validation, it will try to do a
 * partial save of the current values the user has entered, if the
 * response status is not 200, but the error is about duplicated email
 * it will dispatch a requireEmailVerification
 *
 * and return a error object as per redux-form documentation.
 * It needs to dispatch the thunk `submitFormThunk` so inside it has
 * access to the state and then can verify current uid and dataobj_1
 * values,
 *
 * the error thrown is for redux-form email validation error.
 *
 * @param {object} formValues current redux-form values
 * @param {function} dispatch the redux dispatch function
 */
export function asyncValidateForm(formValues, dispatch, props, blurredField) {
  // ideally this should not be called if not email or term
  // check FormPage.shouldAsyncVAlidate
  if (blurredField && blurredField !== EMAIL_FIELD_ID) {
    return Promise.resolve();
  }

  return dispatch(submitFormThunk(formValues))
    .then(response => dispatch(validateFormSuccess(response)))
    .catch(response => {
      if (response.httpStatusCode === DUPLICATED_EMAIL_ERROR_RESPONSE_CODE) {
        // user has applied for other schools
        if (response.errorCode === EXISTING_CONTACT_RESPONSE_CODE) {
          dispatch(foundExistingContact(response.additionalDetails));
          // there is an ongoing application
        } else {
          dispatch(requireEmailVerification(response.additionalDetails));
        }
        throw new Error(EXISTING_LEAD_RESPONSE_CODE);
      } else {
        throw new Error(response.status);
      }
    })
    .catch(error => {
      if (error.message === EXISTING_LEAD_RESPONSE_CODE) {
        //eslint-disable-next-line no-throw-literal
        throw {
          [EMAIL_FIELD_ID]: 'Email Already Exists'
        };
      }
      Logz.error(`Application asyncValidateForm Error Code ${error}`);
      dispatch(validateFormFail());
      //IF THE ERROR is not duplicated email we warn the user for an unknow error in the service
      // eslint-disable-next-line
      throw {
        [EMAIL_FIELD_ID]: 'There is an error in our services please try again later'
      };
    });
}

/**
 * Function for submitting fields from the welcome page.
 * Re-uses asyncValidateEmail, passing the dispatch,
 * and sets verifyingEmail flag for the button spinner
 *
 * @param formValues
 * @returns {Function}
 */
export function submitWelcomeForm(formValues) {
  return dispatch => {
    dispatch(verifyingEmail());
    return asyncValidateForm(formValues, dispatch)
      .then(() => {
        dispatch(welcomePageSubmitSuccess());
        dispatch(setInitialEmail(formValues[EMAIL_FIELD_ID]));
      })
      .catch(() => {
        Logz.error(`Application submitWelcomeForm Error`);
      });
  };
}

/**
 * Thunk used to verify the email.
 *
 * @param {object} values values to be send to a partial save
 */
function submitFormThunk(values) {
  return (dispatch, getState) => {
    const {
      application: { applicationData }
    } = getState();

    return requestFormSubmission(mergeSubmissionValues(values, applicationData), { partial: true });
  };
}

/**
 * Action creator that requests a partial save. A uid and dataobj should be available (inside applicationData)
 * as this action occurs, after a new lead is created or an existing one is retrieved,
 * otherwise they can be returned in the response as this would act to create a new lead if
 * minimum required fields are met.
 *
 * - sets the returned `uid` in the state
 * - stores the returned 'uid' in the browser.
 *
 */

export function requestPartialSave(pageNumber) {
  return (dispatch, getState) => {
    // User should not be able to dispatch this action without having
    // form data in the store so we do not need to do null check
    const {
      form,
      application: { applicationData, saving }
    } = getState();
    const { values: currentValues } = form[SCHEMA_FORM_NAME];

    // Execute partial save if not already saving
    if (!saving) {
      dispatch(partialSaveStart());
      return requestFormSubmission(mergeSubmissionValues(currentValues, applicationData), {
        partial: true
      })
        .then(response => {
          //we have to getstate again since values may have changed
          const formState = getState().form;
          const postSaveValues = formState && formState.schemaForm && formState.schemaForm.values;
          dispatch(partialSaveSuccess(response, postSaveValues));
          return response;
        })
        .catch(response => {
          if (response.httpStatusCode === DUPLICATED_EMAIL_ERROR_RESPONSE_CODE) {
            // same school year and term error
            if (response.errorCode === EXISTING_TERM_YEAR_SCHOOL_RESPONSE_CODE) {
              const termYearId = getTermYearForSchool(getState());

              dispatch(existingTermYearSchoolError());
              dispatch(
                updatePageProgress(
                  {
                    [termYearId]: 'error'
                  },
                  pageNumber
                )
              );
            }
          }
          Logz.error(`Application requestPartialSave Error`);
          // we ignore any other error in partial save
          dispatch(partialSaveFail());
        });
    }
  };
}

export function reviewApplication(values) {
  return {
    type: REVIEW_APPLICATION,
    values
  };
}

export function setUIDDataobj(applicationData) {
  return {
    type: SET_UID,
    applicationData
  };
}

/**
 * Creates an entry in the state.progressMap for the current state pageNumber value
 */
export function createPageProgressMap(resetPageProgress) {
  return {
    type: CREATE_PAGE_PROGRESS_MAP,
    resetPageProgress
  };
}

export function updatePageProgress(syncErrors, pageNumber) {
  return {
    type: UPDATE_PAGE_PROGRESS,
    syncErrors,
    pageNumber
  };
}

export function editPageApplication(pageNumber) {
  return goToPageApplication(pageNumber, true);
}

/**
 * This action creator verifies if a lead has not been created for the current application (no uid in the state)
 * or the request to create it has been sent
 * and then dispatches the actions to create the lead and to change to the requested page
 *
 * @param {Number} pageNumber the page number the user wants to navigate to
 */
export function goToPageApplication(pageNumber = 0, skipSave) {
  window.scroll(0, 0);
  return (dispatch, getState) => {
    const {
      application: { pageNumber: statePageNumber }
    } = getState();

    // User is not allowed to navigate to other pages until minimum lead requirements are met
    //  so if we're here we either created a new lead or retrieved an existing one
    // pageNumber here is the next page but we trigger the partial save for the previous page
    !skipSave && dispatch(requestPartialSave(statePageNumber));
    dispatch(navigateToPageApplication(pageNumber));
    dispatch(createPageProgressMap());
  };
}

export function highlightMissingFields() {
  return (dispatch, getState) => {
    const {
      application: { progressMap, pageNumber }
    } = getState();
    const currentPageProgressMap = progressMap[pageNumber] || {};

    if (currentPageProgressMap.visited) {
      const fields = Object.keys(currentPageProgressMap.fields);
      dispatch(touch(SCHEMA_FORM_NAME, ...fields));
    }
  };
}

export function submitApplication(values) {
  return (dispatch, getState) => {
    dispatch(submitApplicationStart());

    const {
      application: { applicationData }
    } = getState();

    return requestFormSubmission(mergeSubmissionValues(values, applicationData))
      .then(() => {
        dispatch(submitApplicationSuccess());
      })
      .catch(() => {
        Logz.error(`Application submitApplication Error`);
        dispatch(submitApplicationFail());
      });
  };
}

export function checkVerificationCode(code) {
  return (dispatch, getState) => {
    const { form } = getState();
    const { values } = form[SCHEMA_FORM_NAME];
    const email = encodeURIComponent(values[EMAIL_FIELD_ID]);

    const url = `${process.env.REACT_APP_EDULINK_URL}/d2c/verify?email=${email}&code=${code}`;

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    dispatch(verifyCodeStart());
    return fetch(url, config)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then(response => {
        dispatch(verifyCodeSuccess(response));
      })
      .catch(statusErrorCode => {
        Logz.error(`Application checkVerificationCode Error Code ${statusErrorCode}`);
        dispatch(verifyCodeFail(statusErrorCode));
      });
  };
}

export function generateVerificationCode() {
  return (dispatch, getState) => {
    dispatch(generateVerificationCodeStart());

    const { form } = getState();
    const { values } = form[SCHEMA_FORM_NAME];
    const email = encodeURIComponent(values[EMAIL_FIELD_ID]);

    const url = `${process.env.REACT_APP_EDULINK_URL}/d2c/verify?email=${email}`;
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetch(url, config)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.status);
        }
      })
      .then(() => {
        dispatch(generateVerificationCodeSuccess());
      })
      .catch(statusErrorCode => {
        Logz.error(`Application generateVerificationCode Error Code ${statusErrorCode}`);
        dispatch(generateVerificationCodeFail());
      });
  };
}

export function cancelVerification() {
  return { type: CANCEL_EMAIL_VERIFICATION };
}

export function continueApplication() {
  return (dispatch, getState) => {
    const {
      application: {
        applicationData: { uid }
      }
    } = getState();

    dispatch(continueApplicationRequestStart());

    return dispatch(fetchSchema(uid))
      .then(() => {
        dispatch(continueApplicationRequestSuccess());
      })
      .then(() => {
        const data = getState().schema.schema.data;
        const fieldIdsWithValue = Object.keys(data).filter(fieldId => data[fieldId] !== null);
        dispatch(touch(SCHEMA_FORM_NAME, ...fieldIdsWithValue));
      })
      .catch(() => {
        Logz.error(`Application continueApplication Error`);
        //if fetching the schema fails, fallback to generic site
        redirectElsewhere();
      });
  };
}

export function updateContactAddApplicationParams() {
  return (dispatch, getState) => {
    const {
      application: { params }
    } = getState();

    const modifiedParams = {
      ...params,
      cfm_level: `${params.cfm_level}-add`
    };

    dispatch(setQueryParams(modifiedParams));
  };
}

export function addApplicationToContact() {
  return dispatch => {
    dispatch(destroy(SCHEMA_FORM_NAME));
    dispatch(updateContactAddApplicationParams());
    return dispatch(continueApplication()).then(() => dispatch(createPageProgressMap(true)));
  };
}

export function blockingDialogShowed() {
  return {
    type: BLOCKIN_DIALOG_SHOWED
  };
}

export const initialState = {
  requireEmailVerification: false,
  invalidEmail: true,
  submitError: false,
  submitting: false,
  completed: false,
  review: false,
  existingContactWithEmail: false,
  progressMap: {},
  pageNumber: 0,
  values: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_APPLICATION_START:
      return {
        ...state,
        submitting: true
      };
    case SUBMIT_APPLICATION_SUCCESS: {
      return {
        ...initialState,
        completed: true
      };
    }
    case SUBMIT_APPLICATION_FAIL: {
      return {
        ...state,
        submitting: false,
        submitError: true
      };
    }
    case PARTIAL_SAVE_START: {
      return {
        ...state,
        saving: true
      };
    }
    case VALIDATE_FORM_FAIL: {
      return {
        ...state,
        invalidEmail: true
      };
    }
    case VALIDATE_FORM_SUCCESS:
    case PARTIAL_SAVE_SUCCESS: {
      let currentApplicationData = state.applicationData || {};

      // Get rid of any dataobjs in the previous state and only take the
      // ones coming back in the response
      currentApplicationData = removeDataObjs(currentApplicationData);

      return {
        ...state,
        applicationData: { ...currentApplicationData, ...action.applicationData },
        values: action.values,
        requireEmailVerification: false,
        invalidEmail: false,
        saving: false,
        creatingLead: false,
        verifyingEmail: false,
        lastSaveTime: new Date()
      };
    }
    case PARTIAL_SAVE_FAIL: {
      return {
        ...state,
        saving: false,
        verifyingEmail: false
      };
    }
    case GENERATE_VERIFICATION_CODE_START: {
      return {
        ...state,
        requestingVerificationCode: true,
        invalidCode: false,
        expiredCode: false
      };
    }
    case GENERATE_VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        requestingVerificationCode: false,
        verificationCodeError: false,
        verificationCodeGenerated: true
      };
    }
    case GENERATE_VERIFICATION_CODE_FAIL: {
      return {
        ...state,
        requestingVerificationCode: false,
        verificationCodeError: true,
        verificationCodeGenerated: false
      };
    }
    case VERIFY_CODE_START: {
      return {
        ...state,
        verifyingCode: true
      };
    }
    case VERIFY_CODE_SUCCESS: {
      const { applicationData } = action;
      return {
        ...state,
        applicationData,
        codeVerified: true,
        verifyingCode: false,
        invalidCode: false,
        expiredCode: false,
        verificationCodeError: false,
        verificationCodeGenerated: false
      };
    }
    case VERIFY_CODE_FAIL: {
      const { errorCode } = action;
      return {
        ...state,
        verifyingCode: false,
        invalidCode: errorCode === INVALID_VERIFICATION_CODE_ERROR,
        expiredCode: errorCode === EXPIRED_VERIFICATION_CODE_ERROR,
        verificationCodeError: !KNOW_VERIFICATION_CODE_ERRORS.includes(errorCode)
      };
    }
    case REQUIRE_EMAIL_VERIFICATION: {
      return {
        ...state,
        requireEmailVerification: true,
        existingStudentDetails: action.additionalDetails
      };
    }
    case FOUND_EXISTING_CONTACT: {
      return {
        ...state,
        existingContactWithEmail: true,
        existingStudentDetails: action.additionalDetails
      };
    }
    case SAME_YEAR_TERM_SCHOOL_ERROR: {
      return {
        ...state,
        sameYearTermError: true
      };
    }
    case CLEAR_TERM_AND_YEAR_ERROR: {
      return {
        ...state,
        sameYearTermError: false
      };
    }
    case CANCEL_EMAIL_VERIFICATION: {
      const { existingStudentDetails, ...otherState } = state;
      return {
        ...otherState,
        verifyingCode: false,
        invalidCode: false,
        expiredCode: false,
        requireEmailVerification: false,
        requestingVerificationCode: false,
        verificationCodeError: false,
        verificationCodeGenerated: false,
        existingContactWithEmail: false,
        verifyingEmail: false
      };
    }
    case CONTINUE_APPLICATION_REQUEST_START: {
      return {
        ...state,
        retrievingApplication: true
      };
    }
    case CONTINUE_APPLICATION_REQUEST_SUCCESS: {
      const { existingStudentDetails, ...otherState } = state;
      return {
        ...otherState,
        invalidEmail: false,
        codeVerified: false,
        retrievingApplication: false,
        requireEmailVerification: false,
        existingContactWithEmail: false
      };
    }
    case SET_UID: {
      const { applicationData } = action;

      return {
        ...state,
        applicationData
      };
    }
    case REVIEW_APPLICATION: {
      return {
        ...state,
        review: true,
        values: action.values
      };
    }
    case GO_TO_PAGE_APPLICATION: {
      // we mark the current page as already visited
      const { pageNumber: currentPageIndex, progressMap } = state;

      const currentPageProgress = { ...progressMap[currentPageIndex] };
      const updatedProgressMap = {
        ...progressMap,
        [currentPageIndex]: {
          ...currentPageProgress,
          visited: true
        }
      };

      return {
        ...state,
        review: false,
        pageNumber: action.pageNumber,
        progressMap: updatedProgressMap
      };
    }
    case SET_QUERY_PARAMS: {
      return {
        ...state,
        params: action.params
      };
    }
    case UPDATE_PAGE_PROGRESS: {
      const { syncErrors, pageNumber: actionPageNumber } = action;
      const { progressMap, pageNumber: statePageNumber } = state;

      // if action contains a pageNumber we use it, otherwise default to state pageNUmber
      const currentPageIndex = actionPageNumber || statePageNumber;

      if (!progressMap[currentPageIndex]) {
        return state;
      }

      //progress for this page exists, so update it based on sync errors
      const pageProgress = { ...progressMap[currentPageIndex] };
      Object.keys(pageProgress.fields).forEach(key => {
        //clear previous required values
        pageProgress.fields[key] = '';
      });
      if (syncErrors) {
        //set required fields based on sync errors
        Object.assign(pageProgress.fields, syncErrors);

        //if fields is empty object means we do not have errors
        pageProgress.percentComplete = Object.keys(pageProgress.fields).length
          ? ((Object.keys(pageProgress.fields).length - Object.keys(syncErrors).length) /
              Object.keys(pageProgress.fields).length) *
            100
          : 100;
      }

      const newProgressMap = {
        ...progressMap,
        [currentPageIndex]: pageProgress
      };

      return {
        ...state,
        progressMap: newProgressMap
      };
    }
    case CREATE_PAGE_PROGRESS_MAP: {
      const { progressMap, pageNumber } = state;
      const { resetPageProgress } = action;

      if (progressMap[pageNumber] && !resetPageProgress) {
        return state;
      }
      // progress for this page doesn't exist
      const newProgress = { ...progressMap };
      const pageProgress = { fields: {}, percentComplete: 100 };

      newProgress[pageNumber] = pageProgress;
      return { ...state, progressMap: newProgress };
    }
    case OPEN_CONSENT_DIALOG: {
      return {
        ...state,
        consentDialogOpen: true
      };
    }
    case CLOSE_CONSENT_DIALOG: {
      return {
        ...state,
        consentDialogOpen: false
      };
    }
    case ACCEPT_CONSENT: {
      return {
        ...state,
        consentAccepted: true
      };
    }
    case VERIFYING_EMAIL: {
      return {
        ...state,
        verifyingEmail: true
      };
    }
    case SET_INITIAL_EMAIL: {
      return {
        ...state,
        initialEmail: action.value
      };
    }
    default:
      return state;
  }
}
