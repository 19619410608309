const primaryMain = '#17315A';
const primaryMainOpacity = 'rgba(23, 49, 90, 0.9)';
const secondaryMain = '#00A4E9';
const secondaryMainOpacity = 'rgba(0, 164, 233, 0.5)';
const secondaryLight = '#8FB7E1';
const tertiaryMain = '#124573';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain
};
