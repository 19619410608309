import { FEEDBACK_MESSAGES } from '../constants/constants';
import { Logz } from './configureLogz';

import {
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_START,
  FILE_UPLOAD_FAIL,
  SELECT_FILES,
  REMOVE_FILE,
  SELECT_MANIFEST_FILE,
  REMOVE_MANIFEST_FILE
} from './upload.constants';

function fileUploadStart() {
  return {
    type: FILE_UPLOAD_START
  };
}

function fileUploadSuccess(error) {
  return { type: FILE_UPLOAD_SUCCESS };
}

function fileUploadFail(error) {
  return {
    type: FILE_UPLOAD_FAIL,
    error
  };
}

export function fileUpload(files) {
  return (dispatch, getState) => {
    const uid = getState().application.applicationData.uid;

    const url = `${process.env.REACT_APP_EDULINK_URL}/d2c/upload/${uid}/documents/`;
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      //have to do a traditional for loop since map functions don't work on a fileList
      if (/^(.*\.(?!(jpg|jpeg|png|pdf|doc|docx)$))?[^.]*$/i.test(files[i].name)) {
        dispatch(fileUploadFail(`unknow extention file ${files[i].name}`));
        return Promise.reject(false);
      } else {
        data.append('files', files[i]);
      }
    }
    dispatch(fileUploadStart());

    const config = {
      method: 'POST',
      credentials: 'same-origin',
      body: data
    };

    return fetch(url, config)
      .then(response => {
        if (response.status === 200) {
          return dispatch(fileUploadSuccess());
        } else if (response.status === 413) {
          dispatch(fileUploadFail(FEEDBACK_MESSAGES.BULK_UPLOAD_ERROR_SIZE));
        } else {
          return response.json().then(json => {
            throw Error(json);
          });
        }
      })
      .catch(error => {
        Logz.error(`Upload Error ${error.message || error}`);
        dispatch(fileUploadFail(error.message));
        return Promise.reject(error);
      });
  };
}

export function selectFiles(selectedFiles) {
  return {
    type: SELECT_FILES,
    selectedFiles
  };
}

export function selectManifestFile(selectedFile, index) {
  return {
    type: SELECT_MANIFEST_FILE,
    selectedFile,
    index
  };
}

export function removeManifestFile(index) {
  return {
    type: REMOVE_MANIFEST_FILE,
    index
  };
}

export function removeFile(index) {
  return {
    type: REMOVE_FILE,
    index
  };
}

export const initialState = {
  uploading: false,
  uploadError: false,
  selectedFiles: [],
  uploadedFiles: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FILES: {
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, ...action.selectedFiles]
      };
    }
    case REMOVE_FILE: {
      const updatedFiles = [...state.selectedFiles];
      updatedFiles.splice(action.index, 1);

      return {
        ...state,
        selectedFiles: updatedFiles
      };
    }
    case SELECT_MANIFEST_FILE: {
      const updatedFiles = [...state.selectedFiles];

      const indexOf = state.selectedFiles.findIndex(file => file.index === action.index);

      if (indexOf < 0) {
        updatedFiles.push(action.selectedFile);
      } else {
        // there is a file with same index so we are replacing the file
        updatedFiles.splice(indexOf, 1, action.selectedFile);
      }

      return {
        ...state,
        selectedFiles: updatedFiles
      };
    }
    case REMOVE_MANIFEST_FILE: {
      const updatedFiles = state.selectedFiles.filter(file => file.index !== action.index);
      return {
        ...state,
        selectedFiles: updatedFiles
      };
    }
    case FILE_UPLOAD_START:
      return {
        ...state,
        uploading: true
      };
    case FILE_UPLOAD_SUCCESS: {
      const lastUploaded = state.selectedFiles.map(file => Object.assign(file, { uploaded: true }));

      const uploadedFiles = [...state.uploadedFiles, ...lastUploaded];
      return {
        ...state,
        uploadedFiles,
        uploading: false,
        uploadError: false,
        selectedFiles: []
      };
    }
    case FILE_UPLOAD_FAIL: {
      return {
        ...state,
        uploading: false,
        uploadError: true
      };
    }
    default:
      return state;
  }
}
