import React from 'react';
import PropTypes from 'prop-types';
import compose from 'compose-function';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import withStyles from '@material-ui/core/styles/withStyles';

import SchoolTypography from '@shorelight/super-form/components/SchoolTypography';

import DialogTitle from './DialogTitle';
import { closeConsentDialog, acceptConsent } from '../../actions/application';

const styles = theme => ({
  contentRoot: {
    paddingTop: theme.spacing(),
    overflowY: 'unset'
  },
  dialogText: {
    paddingBottom: theme.spacing()
  },
  rootTitle: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500]
  },
  controlsContainer: {
    marginTop: theme.spacing(),
    '& > *': {
      marginBottom: theme.spacing(),
      marginTop: theme.spacing()
    }
  },
  instructionText: {
    fontSize: '1.1rem'
  }
});

export class ConsentDialog extends React.PureComponent {
  acceptConsent = () => {
    const { closeConsentDialog, handleAcceptConsent } = this.props;
    handleAcceptConsent();
    closeConsentDialog();
  };

  render() {
    const { classes, fullScreen, closeConsentDialog } = this.props;

    return (
      <Dialog open={true} fullScreen={fullScreen}>
        <DialogTitle
          showCloseButton
          onDialogClose={closeConsentDialog}
          title="Consent and Notice"
        />
        <DialogContent classes={{ root: classes.contentRoot }}>
          <Grid container justify="center" className={classes.controlsContainer}>
            <SchoolTypography color="secondaryText" className={classes.instructionText}>
              <b>Read and accept at the end</b>
            </SchoolTypography>
            <SchoolTypography color="primaryText" align="center">
              <b>CONSENT AND NOTICE REGARDING ELECTRONIC COMMUNICATIONS</b>
            </SchoolTypography>
            <Grid item xs={12}>
              <DialogContentText>
                <b>1. Electronic Signature Agreement. </b>
                By clicking the box on the Accelerator Program application form, you are signing
                this Agreement electronically. You agree your electronic signature is the legal
                equivalent of your manual signature on this Agreement. By selecting &quot;I
                Accept&quot;, you consent to be legally bound by this Agreement&apos;s terms and
                conditions. You further agree that your use of a keypad, mouse or other device to
                select an item, button, icon or similar act/action, or in accessing or making any
                transaction regarding any agreement, acknowledgment, consent terms, disclosures or
                conditions constitutes your signature (hereafter referred to as
                &quot;E-Signature&quot;), acceptance and agreement as if actually signed by you in
                writing. You also agree that no certification authority or other third-party
                verification is necessary to validate your E-Signature and that the lack of such
                certification or third-party verification will not in any way affect the
                enforceability of your E-Signature or any resulting contract between you and your
                university program, its operator and its parent, Shorelight Education, LLC (the
                “Program”). You also represent that you are authorized to enter into this Agreement
                for all persons who own or are authorized to access any of your accounts and that
                such persons will be bound by the terms of this Agreement.
              </DialogContentText>
            </Grid>
            <Grid>
              <DialogContentText>
                <b>2. Consent to Electronic Delivery. </b> You specifically agree to receive and/or
                obtain any and all Program-related &quot;Electronic Communications&quot; (defined
                below). The term &quot;Electronic Communications&quot; includes, but is not limited
                to, any and all current and future notices and/or disclosures that various federal
                and/or state laws or regulations require that we provide to you, as well as such
                other documents, statements, data, records and any other communications regarding
                your relationship with the Program. You acknowledge that, for your records, you are
                able to retain Electronic Communications by printing and/or downloading and saving
                this Agreement and any other agreements and Electronic Communications, documents, or
                records that you agree to using your E-Signature. You accept Electronic
                Communications provided by the Program as reasonable and proper notice, for the
                purpose of any and all laws, rules and regulations, and agree that such electronic
                form fully satisfies any requirement that such communications be provided to you in
                writing or in a form that you may keep.
              </DialogContentText>
            </Grid>
            <Grid>
              <DialogContentText>
                <b>3. Paper version of Electronic Communications. </b> You may request a paper
                version of an Electronic Communication. You acknowledge that the Program reserves
                the right to charge you a reasonable fee for the production and mailing of paper
                versions of Electronic Communications. To request a paper copy of an Electronic
                Communication, contact us at admissions@shorelight.com.
              </DialogContentText>
            </Grid>
            <Grid>
              <DialogContentText>
                <b>4. Revocation of electronic delivery. </b> You have the right to withdraw your
                consent to receive/obtain Electronic Communications at any time. If you wish to
                withdraw your consent, contact us at admissions@shorelight.com.
              </DialogContentText>
            </Grid>
            <Grid>
              <DialogContentText>
                <b>5. Valid and current email address, notification and updates. </b> Your current
                valid email address is required in order for you to receive Electronic
                Communications. You agree to keep the Program informed of any changes in your email
                address. You may modify your email address by submitting a written request to the
                Program, including by email.
              </DialogContentText>
            </Grid>
            <Grid>
              <DialogContentText>
                <b>6. Hardware, software and operating system. </b> You are responsible for
                installation, maintenance and operation of your computer, browser and software. The
                Program is not responsible for errors or failures from any malfunction of your
                computer, browser or software. The Program, its parent or affiliates are also not
                responsible for computer viruses or related problems associated with use of an
                online system.
              </DialogContentText>
            </Grid>
            <Grid>
              <DialogContentText>
                <b>7. Controlling Agreement. </b> This Agreement supplements and modifies other
                agreements that you may have with the Program. To the extent that this Agreement and
                another agreement contain conflicting provisions, the provisions in this agreement
                will control (with the exception of provisions in another agreement for an
                electronic service whose provisions specify the necessary hardware, software and
                operating system, in which such other provision controls). All other obligations of
                the parties remain subject to the terms and conditions of any other agreement.
              </DialogContentText>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.verifyContainer}>
              <Button fullWidth color="secondary" variant="contained" onClick={this.acceptConsent}>
                I ACCEPT
              </Button>
              <Button fullWidth color="secondary" onClick={closeConsentDialog}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

ConsentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeConsentDialog: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  handleAcceptConsent: PropTypes.func.isRequired
};

ConsentDialog.defaultProps = {
  showExistingContactDialog: false
};

const mapDispatchToProps = {
  closeConsentDialog,
  handleAcceptConsent: acceptConsent
};

export default compose(
  withMobileDialog({ breakpoint: 'xs' }),
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(ConsentDialog);
