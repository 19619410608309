const primaryMain = '#005A8B';
const primaryMainOpacity = 'rgba(0, 90, 139, 0.9)';
const secondaryMain = '#124B94';
const secondaryMainOpacity = 'rgba(1, 87, 173, 0.5)';
const secondaryLight = '#A0CFEB';
const tertiaryMain = '#004266';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  logoContainer: {
    height: 20,
    marginTop: 30
  },
  logoContainerMobile: {
    height: 20,
    marginBottom: 25
  },
  headerPaddingTop: 38,
  headerPaddingBottom: 38
};
