const primaryMain = '#000000';
const primaryMainOpacity = 'rgba(0, 0, 0, 0.9)';
const secondaryMain = '#FFD200';
const secondaryMainOpacity = 'rgba(255, 210, 0, 0.5)';
const secondaryLight = '#FFD200';
const tertiaryMain = '#535353';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
