/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';

import withStyles from '@material-ui/core/styles/withStyles';

import SecondActionButton from '@shorelight/super-form/components/SecondActionButton';
import FormProgressButton from '@shorelight/super-form/components/FormProgressButton';
import TextFieldIconAdornment from '@shorelight/super-form/components/TextFieldIconAdornment';

const styles = theme => ({
  controlsContainer: {
    marginTop: theme.spacing(),
    '& > *': {
      marginBottom: theme.spacing(),
      marginTop: theme.spacing()
    }
  },
  verificationStatusContainer: {
    marginTop: theme.spacing(2),
    marginBottom: 0
  },
  verificationTextField: {
    backgroundColor: theme.palette.fieldset.main
  },
  verificationInput: {
    fontSize: 20,
    letterSpacing: 4,
    textAlign: 'center',
    fontFamily: 'Montserrat',
    color: theme.palette.text.primaryText,
    '&::placeholder': {
      color: theme.palette.text.secondary
    }
  },
  DialogButton: {
    '&$disabled': {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.action.disabledBackground,
      color: theme.palette.common.white
    }
  },
  disabled: {},
  codeGeneratedMessage: {
    color: theme.palette.text.secondaryText,
    fontSize: '.9rem',
    display: 'block',
    marginBottom: theme.spacing()
  }
});

export class VerifyCodeForm extends React.PureComponent {
  state = {
    verificationCode: ''
  };

  handleCodeChange = event => {
    const verificationCode = event.target.value;

    // set new value only if no more than 6 digits
    if (verificationCode.length < 7) {
      this.setState(state => {
        return { verificationCode };
      });
    }
  };

  handleGenerateVerificationCode = event => {
    this.setState({ verificationCode: '' });
    this.props.sendVerificationCode();
  };

  handleSubmitVerificationCode = event => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onSubmitVerificationCode(this.state.verificationCode);
  };

  renderStepInstructions = () => {
    const {
      verifyingCode,
      invalidCode,
      expiredCode,
      verificationCodeError,
      verificationCodeGenerated,
      classes
    } = this.props;

    if (verificationCodeError) {
      return (
        <Typography align="center" color="error" variant="h6">
          There Was An Unexpected Error Please Try Again
        </Typography>
      );
    }
    if (invalidCode || expiredCode) {
      return (
        <Typography align="center" color="error" variant="body2">
          {invalidCode && 'Please enter a valid code.'}
          {expiredCode && 'Your verfication code has expired.'}
        </Typography>
      );
    }
    return (
      <DialogContentText align="center">
        {verificationCodeGenerated && (
          <span className={classes.codeGeneratedMessage}>Code sent! Please check your inbox.</span>
        )}
        {verifyingCode
          ? 'Verifying your identity'
          : 'Enter your code below to verify your identity'}
      </DialogContentText>
    );
  };

  render() {
    const {
      classes,
      invalidCode,
      expiredCode,
      verifyingCode,
      requestingVerificationCode,
      verificationCodeError,
      verificationCodeGenerated
    } = this.props;

    const { verificationCode } = this.state;

    const sendCodeButtonMessage =
      !verificationCodeGenerated && !verificationCodeError ? 'send code' : 'send new code';

    return (
      <form onSubmit={this.handleSubmitVerificationCode}>
        <Grid container justify="center" className={classes.controlsContainer}>
          <Grid item xs={8} sm={6}>
            <SecondActionButton
              fullWidth
              onClick={this.handleGenerateVerificationCode}
              inProgress={requestingVerificationCode}
            >
              {sendCodeButtonMessage}
            </SecondActionButton>
          </Grid>
          <Grid item xs={12} className={classes.verificationStatusContainer}>
            {this.renderStepInstructions()}
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              type="number"
              variant="outlined"
              id="verificationCodeInput"
              className={classes.verificationTextField}
              error={invalidCode || expiredCode}
              value={verificationCode}
              onChange={this.handleCodeChange}
              InputProps={{
                startAdornment: (
                  <TextFieldIconAdornment
                    icon="lock"
                    hasError={invalidCode || expiredCode}
                    touched={invalidCode || expiredCode}
                  />
                ),
                classes: {
                  input: classes.verificationInput
                }
              }}
              inputProps={{ placeholder: '000000', min: 1, max: 999999 }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormProgressButton
              fullWidth
              type="submit"
              color="secondary"
              variant="contained"
              id="verifyCodeAction"
              inProgress={verifyingCode}
              disabled={!verificationCode}
              className={classnames(classes.DialogButton, {
                [classes.disabled]: !verificationCode
              })}
            >
              VERIFY ME
            </FormProgressButton>
          </Grid>
        </Grid>
      </form>
    );
  }
}

VerifyCodeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  //code error status
  invalidCode: PropTypes.bool.isRequired,
  expiredCode: PropTypes.bool.isRequired,
  //request code status
  verificationCodeError: PropTypes.bool.isRequired,
  verificationCodeGenerated: PropTypes.bool.isRequired,
  //action in progress indicators
  verifyingCode: PropTypes.bool.isRequired,
  requestingVerificationCode: PropTypes.bool.isRequired,
  //callbacks
  sendVerificationCode: PropTypes.func.isRequired,
  onSubmitVerificationCode: PropTypes.func.isRequired
};

export default withStyles(styles)(VerifyCodeForm);
