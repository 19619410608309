const primaryMain = '#FFC425';
const primaryMainOpacity = 'rgba(255, 196, 37, 0.9)';
const secondaryMain = '#492F24';
const secondaryMainOpacity = 'rgba(73, 47, 36, 0.5)';
const secondaryLight = '#2A3E60';
const tertiaryMain = '#492F24';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
