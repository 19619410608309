const primaryMain = '#00267A';
const primaryMainOpacity = 'rgba(0, 38, 122, 0.9)';
const secondaryMain = '#078CF5';
const secondaryMainOpacity = 'rgba(7, 140, 245, 0.5)';
const secondaryLight = '#001B56';
const tertiaryMain = '#001B56';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain
};
