const primaryMain = '#5E6A71';
const primaryMainOpacity = 'rgba(94, 106, 113, 0.9)';
const secondaryMain = '#0098DB';
const secondaryMainOpacity = 'rgba(0, 152, 219, 0.5)';
const secondaryLight = '#00549F';
const tertiaryMain = '#95A0A9';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
