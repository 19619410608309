const primaryMain = '#1E1656';
const primaryMainOpacity = 'rgba(30, 22, 86, 0.9)';
const secondaryMain = '#0098C3';
const secondaryMainOpacity = 'rgba(0, 152, 195, 0.5)';
const secondaryLight = '#EEAF30';
const tertiaryMain = '#352E66';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
