const primaryMain = '#163E70';
const primaryMainOpacity = 'rgba(22, 62, 112, 0.9)';
const secondaryMain = '#00AEEF';
const secondaryMainOpacity = 'rgba(0, 174, 239, 0.5)';
const secondaryLight = '#0096D6';
const tertiaryMain = '#231F20';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
