import { $white, $black } from './commonColors';

const primaryMain = '#F47920';
const primaryMainOpacity = 'rgba(244, 121, 32, 0.9)';
const secondaryMain = $black;
const secondaryMainOpacity = 'rgba(0, 0, 0, 0.5)';
const secondaryLight = '#762123';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: secondaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryLight,
  contrastText: $white,
  formNavBar: {
    stepperBackgroundColor: secondaryMain,
    stepperBackgroundColorMobile: secondaryMain,
    currentStepButtonTextColor: secondaryMain
  }
};
