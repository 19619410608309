const primaryMain = '#BA1C21';
const primaryMainOpacity = 'rgba(186, 28, 33, 0.9)';
const secondaryMain = '#003058';
const secondaryMainOpacity = 'rgba(0, 48, 88, 0.5)';
const secondaryLight = '#003058';
const tertiaryMain = '#C2C5C8';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};