import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import compose from 'compose-function';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import DialogTitle from './DialogTitle';
import FormFieldHelperLabel from '@shorelight/super-form/components/FormFieldHelperLabel';

const styles = theme => ({
  contentRoot: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  bold: {},
  text: {
    paddingBottom: theme.spacing(),
    '&$bold': {
      fontWeight: 'bold'
    }
  }
});

export class SameYearTermErrorDialog extends React.PureComponent {
  render() {
    const { classes, fullScreen, onCloseDialog } = this.props;
    return (
      <Dialog open fullScreen={fullScreen}>
        <DialogTitle showCloseButton onDialogClose={onCloseDialog} title="We're sorry ..." />
        <DialogContent classes={{ root: classes.contentRoot }}>
          <FormFieldHelperLabel variant="body2" gutterBottom className={classes.text}>
            Our records indicate that you have already applied to this school with the same starting
            term and starting year.
          </FormFieldHelperLabel>
          <FormFieldHelperLabel variant="body2" className={classnames(classes.text, classes.bold)}>
            Please select a different starting term or starting year to continue your application.
          </FormFieldHelperLabel>
        </DialogContent>
      </Dialog>
    );
  }
}

SameYearTermErrorDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired
};

export default compose(
  withMobileDialog({ breakpoint: 'xs' }),
  withStyles(styles)
)(SameYearTermErrorDialog);
