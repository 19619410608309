const primaryMain = '#98002E';
const primaryMainOpacity = 'rgba(152, 0, 46, 0.9)';
const secondaryMain = '#550B21';
const secondaryMainOpacity = 'rgba(85, 11, 33, 0.5)';
const secondaryLight = '#C4A006';
const tertiaryMain = '#550B21';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
