const primaryMain = 'rgb(0,0,0)';
const primaryMainOpacity = 'rgb(130,130,130)';
const secondaryMain = 'rgb(255, 255,255)';
const secondaryMainOpacity = 'rgb(255, 205, 0)';
const secondaryLight = 'rgb(130,130,130)';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryLight,
  contrastText: primaryMain,
  headerColor: secondaryMain,
  progressButton: {
    color: primaryMain
  },
  nextIcon: {
    backgroundColor: primaryMain,
    color: secondaryMain
  },
  logoContainer: {
    height: 50,
    marginTop: 12
  },
  formNavBar: {
    stepperBackgroundColor: primaryMainOpacity,
    stepperBackgroundColorMobile: secondaryMain,
    currentStepButtonTextColor: primaryMain,
    stepLabelTextColor: primaryMain,
    stepButtonTextColor: primaryMain
  },
  formPageActions: {
    continueIconColor: secondaryMain,
    continueSupportLinkColor: secondaryMain
  },
  formTextBlock: {
    alertBackgroundColor: primaryMain,
    alertFontColor: secondaryMain,
    alertIconBackgroundColor: secondaryLight
  },
  MuiFab: {
    primary: {
      backgroundColor: secondaryMain,
      color: primaryMain
    }
  }
};
