const primaryMain = '#752936';
const primaryMainOpacity = 'rgba(117, 41, 54, 0.9)';
const secondaryMain = '#4D4D4D';
const secondaryMainOpacity = 'rgba(77, 77, 77, 0.5)';
const secondaryLight = '#00667A';
const tertiaryMain = '#4D4D4D';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
