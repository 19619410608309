const primaryMain = '#601C2E';
const primaryMainOpacity = 'rgba(96, 28, 46, 0.9)';
const secondaryMain = '#00508E';
const secondaryMainOpacity = 'rgba(0, 80, 142, 0.5)';
const secondaryLight = '#F4524D';
const tertiaryMain = '#930032';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
