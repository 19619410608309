const primaryMain = '#EA0029';
const primaryMainOpacity = 'rgba(234, 0, 41, 0.9)';
const secondaryMain = '#A50034';
const secondaryMainOpacity = 'rgba(165, 0, 52, 0.5)';
const secondaryLight = '#A50034';
const tertiaryMain = '#A50034';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
