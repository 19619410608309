const primaryMain = '#002855';
const primaryMainOpacity = 'rgba(0, 40, 85, 0.9)';
const secondaryMain = '#DA1A32';
const secondaryMainOpacity = 'rgba(218, 26, 50, 0.5)';
const secondaryLight = '#49739D';
const tertiaryMain = '#000000';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
