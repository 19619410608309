const primaryMain = '#002855';
const primaryMainOpacity = 'rgba(0, 40, 85, 0.9)';
const secondaryMain = '#FF8200';
const secondaryMainOpacity = 'rgba(255, 130, 0, 0.5)';
const secondaryLight = '#3594D1';
const tertiaryMain = '#253858';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
