import React from 'react';
import { reduxForm } from 'redux-form';

import SuperFormWizard from '@shorelight/super-form/components/SuperFormWizard';
import { shouldFormAsyncValidate } from '@shorelight/super-form/utils/SuperFormUtils/forms';

import { asyncValidateForm } from '../../actions/application';
import { SCHEMA_FORM_NAME, EMAIL_FIELD_ID } from '../../constants/constants';

export const SuperFormWizardComponent = props => {
  return <SuperFormWizard {...props} />;
};

const ReduxSuperForm = reduxForm({
  form: SCHEMA_FORM_NAME,
  enableReinitialize: true,
  shouldAsyncValidate: shouldFormAsyncValidate,
  asyncValidate: asyncValidateForm,
  asyncBlurFields: [EMAIL_FIELD_ID],
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(SuperFormWizardComponent);

export default ReduxSuperForm;
