const primaryMain = '#06274F';
const primaryMainOpacity = 'rgba(6, 39, 79, 0.9)';
const secondaryMain = '#06274F';
const secondaryMainOpacity = 'rgba(6, 39, 79, 0.5)';
const secondaryLight = '#4D79A3';
const tertiaryMain = '#013055';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  logoContainerMobile: {
    height: 30,
    marginBottom: 20
  }
};
