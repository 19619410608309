import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = theme => ({
  dialogText: {
    paddingBottom: theme.spacing()
  },
  agencyDetailsContainer: {
    backgroundColor: theme.palette.fieldset.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(-3),
    marginRight: theme.spacing(-3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(8)
    }
  },
  agentEmailLink: {
    color: theme.palette.text.secondaryText
  },
  supportLink: {
    color: theme.palette.text.secondary
  }
});

export class AdmissionsPortalApplicantError extends React.PureComponent {
  render() {
    const { existingAgencyDetails, supportEmail, classes } = this.props;
    return (
      <>
        <DialogContentText className={classes.dialogText}>
          Our records indicate that the student associated with this email address is already
          working with a partner agency.
        </DialogContentText>
        <DialogContentText className={classes.dialogText}>
          <strong>
            Please contact your agent to complete your application for dual enrollment.
          </strong>
        </DialogContentText>
        <>
          <div className={classes.agencyDetailsContainer}>
            <DialogContentText>
              <b>{existingAgencyDetails.agencyName}</b>
            </DialogContentText>
            <DialogContentText>{existingAgencyDetails.agentName}</DialogContentText>
            <DialogContentText>
              <a
                className={classes.agentEmailLink}
                href={'mailto:' + existingAgencyDetails.agentEmail}
              >
                {existingAgencyDetails.agentEmail}
              </a>
            </DialogContentText>
          </div>
        </>
        <DialogContentText className={classes.dialogText}>
          If you are unable to reach the agency above, please contact{' '}
          <a className={classes.supportLink} href={'mailto:' + supportEmail}>
            customer support.
          </a>
        </DialogContentText>
      </>
    );
  }
}

AdmissionsPortalApplicantError.propTypes = {
  classes: PropTypes.object.isRequired,
  existingAgencyDetails: PropTypes.shape({
    agencyName: PropTypes.string.isRequired,
    agentName: PropTypes.string.isRequired,
    agentEmail: PropTypes.string.isRequired
  }),
  supportEmail: PropTypes.string.isRequired
};

export default withStyles(styles)(AdmissionsPortalApplicantError);
