const primaryMain = '#154734';
const primaryMainOpacity = 'rgba(21, 71, 52, 0.9)';
const secondaryMain = '#007A33';
const secondaryMainOpacity = 'rgba(0, 122, 51, 0.5)';
const secondaryLight = '#72BF44';
const tertiaryMain = '#007A33';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
