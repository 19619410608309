import React from 'react';
import PropTypes from 'prop-types';

import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import Check from '@material-ui/icons/Check';

import FormProgressButton from '@shorelight/super-form/components/FormProgressButton';
import SchoolTypography from '@shorelight/super-form/components/SchoolTypography';

const styles = theme => ({
  controlsContainer: {
    marginTop: theme.spacing(),
    '& > *': {
      marginBottom: theme.spacing(),
      marginTop: theme.spacing()
    }
  },
  successCheck: {
    color: theme.palette.success.main,
    width: 70,
    height: 70,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  successText: {
    fontWeight: 'bold'
  }
});

export class VerifyDialogContinueApp extends React.PureComponent {
  handleContinueApplication = event => {
    event.preventDefault();
    event.stopPropagation();

    const { continueApplication, addApplicationToContact, existingContactWithEmail } = this.props;

    const handler = existingContactWithEmail ? addApplicationToContact : continueApplication;
    handler();
    window.scroll(0, 0);
  };

  render() {
    const { classes, retrievingApplication, existingContactWithEmail } = this.props;
    return (
      <Grid container justify="center" className={classes.controlsContainer}>
        <Grid item xs={12} container justify="center">
          <Check className={classes.successCheck} />
        </Grid>
        <Grid item xs={12}>
          <SchoolTypography color="primaryText" variant="h6" align="center">
            <b>Success!</b>
          </SchoolTypography>
        </Grid>
        <Grid item xs={12}>
          <DialogContentText align="center">Your identity has been verified.</DialogContentText>
          <DialogContentText align="center">
            {existingContactWithEmail
              ? `Let's review your information and finish your application.`
              : 'Let’s finish your application.'}
          </DialogContentText>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormProgressButton
            fullWidth
            color="secondary"
            variant="contained"
            id="continueMyApplicationAction"
            inProgress={retrievingApplication}
            onClick={this.handleContinueApplication}
          >
            CONTINUE MY APPLICATION
          </FormProgressButton>
        </Grid>
      </Grid>
    );
  }
}

VerifyDialogContinueApp.propTypes = {
  classes: PropTypes.object.isRequired,
  retrievingApplication: PropTypes.bool.isRequired,
  existingContactWithEmail: PropTypes.bool.isRequired,
  continueApplication: PropTypes.func.isRequired,
  addApplicationToContact: PropTypes.func.isRequired
};

export default withStyles(styles)(VerifyDialogContinueApp);
