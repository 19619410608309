import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  bulletItem: {
    paddingLeft: theme.spacing()
  },
  bulletText: {
    padding: 0
  },
  primaryText: {
    color: theme.palette.text.secondary
  },
  icon: {
    fill: theme.palette.text.primaryText
  }
});

export const WelcomeInstructionItem = ({ classes, Icon, primaryText }) => {
  return (
    <ListItem
      disableGutters
      classes={{
        root: classes.bulletItem
      }}
    >
      <ListItemIcon>
        <Icon className={classes.icon} />
      </ListItemIcon>
      <ListItemText
        primary={primaryText}
        classes={{
          root: classes.bulletText,
          primary: classes.primaryText
        }}
        primaryTypographyProps={{
          variant: 'body2'
        }}
      />
    </ListItem>
  );
};

WelcomeInstructionItem.propTypes = {
  primaryText: PropTypes.node.isRequired,
  Icon: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired
};

WelcomeInstructionItem.defaultProps = {};

export default withStyles(styles)(React.memo(WelcomeInstructionItem));
