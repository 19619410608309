import { $black } from './commonColors';

const primaryMain = $black;
const primaryMainOpacity = 'rgba(0, 0, 0, 0.9)';
const secondaryMain = '#EA4148';
const secondaryMainOpacity = 'rgba(234, 65, 72, 0.5)';
const secondaryLight = '#1C2F3A';
const tertiaryMain = '#444';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
