const primaryMain = '#003768';
const primaryMainOpacity = 'rgba(0, 55, 104, 0.9)';
const secondaryMain = '#000000';
const secondaryMainOpacity = 'rgba(0, 0, 0, 0.5)';
const secondaryLight = '#FF8000';
const tertiaryMain = '#000000';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
