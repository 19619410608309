import { $white } from './commonColors';

const darkGrey = '#222222';

const primaryMain = '#C95106';
const primaryMainOpacity = 'rgba(201, 81, 6, 0.9)';
const secondaryMain = darkGrey;
const secondaryMainOpacity = 'rgba(34, 34, 34, 0.5)';
const secondaryLight = darkGrey;

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: secondaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryLight,
  contrastText: $white
};
