const primaryMain = 'rgb(0,45,114)';
const primaryMainOpacity = 'rgb(207,59,32)';
const secondaryMain = 'rgb(114, 172, 229)';
const secondaryMainOpacity = 'rgb(118, 35, 47)';
const secondaryLight = 'rgb(161,90,149)';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryLight,
  contrastText: 'white',
  nextIcon: {
    backgroundColor: primaryMain,
    color: 'white'
  },
  logoContainer: {
    height: 120,
    marginTop: 12,
    top: 'auto'
  },
  formNavBar: {
    stepperBackgroundColor: secondaryMain,
    stepperBackgroundColorMobile: secondaryMain,
    currentStepButtonTextColor: secondaryMain,
    stepLabelTextColor: 'black',
    stepButtonTextColor: 'black'
  },
  formPageActions: {
    continueIconColor: secondaryMain,
    continueSupportLinkColor: secondaryMain
  },
  formTextBlock: {
    alertBackgroundColor: primaryMain,
    alertFontColor: 'white',
    alertIconBackgroundColor: 'white'
  }
};
