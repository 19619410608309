const primaryMain = '#003262';
const primaryMainOpacity = 'rgba(0, 50, 99, 0.9)';
const secondaryMain = '#3B7EA1';
const secondaryMainOpacity = 'rgba(235,115,4, 0.5)';
const secondaryLight = '#FDB515';
const headerColor = '#5E5E5E';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain: secondaryMain,
  logoContainer: {
    height: 24,
    marginTop: 26
  },
  logoContainerMobile: {
    height: 24
  },
  headerColor
};
