const primaryMain = '#003366';
const primaryMainOpacity = 'rgba(0, 51, 102, 0.9)';
const secondaryMain = '#0099ff';
const secondaryMainOpacity = 'rgba(0, 153, 255, 0.5)';
const secondaryLight = '#c8b18b';
const tertiaryMain = '#08243F';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
