const primaryMain = '#143257';
const primaryMainOpacity = 'rgba(20, 50, 87, 0.9)';
const secondaryMain = '#D82732';
const secondaryMainOpacity = 'rgba(216, 39, 50, 0.5)';
const secondaryLight = '#13A3CE';
const tertiaryMain = '#5A6F89';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  logoContainerMobile: {
    height: 30,
    marginBottom: 20
  }
};
