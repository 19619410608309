const primaryMain = '#003C69';
const primaryMainOpacity = 'rgba(0, 60, 105, 0.9)';
const secondaryMain = '#DD6402';
const secondaryMainOpacity = 'rgba(221, 100, 2, 0.5)';
const secondaryLight = '#0091B8';
const tertiaryMain = '#022846';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
