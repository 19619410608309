import { GENERIC_REDIRECT_URL } from '../constants/constants';

const getFlattenProp = propName => (flattenValue, object = {}) => [
  ...flattenValue,
  ...object[propName]
];

export const parseObjectToQueryString = (parametersAsObject = {}) =>
  Object.keys(parametersAsObject)
    .map(key => `${key}=${parametersAsObject[key]}`)
    .join('&');

//get all field ids from schema into a flat array
export function getFieldIds(form = {}) {
  if (form.pages) {
    return form.pages
      .reduce(getFlattenProp('groups'), [])
      .reduce(getFlattenProp('fields'), [])
      .reduce((onlyFields, field) => {
        const fields = ['subfields', 'flex'].includes(field.type) ? field.fields : [field];
        return [...onlyFields, ...fields];
      }, [])
      .reduce((ids, field) => (field.id ? [...ids, field.id] : ids), []);
  }
}

export function parseExistingFiles(existingFiles) {
  return (
    existingFiles &&
    existingFiles.split(',').map(fileName => ({
      name: fileName,
      existingFile: true
    }))
  );
}

//temporary measure to put user somewhere if they try to hit base url or the parameters are wrong
export const redirectElsewhere = () => (window.location.href = GENERIC_REDIRECT_URL);

export const getTermYearForSchool = state => {
  const {
    schema: {
      schema: {
        data: { school }
      }
    }
  } = state;

  return `termYear${school}`;
};
