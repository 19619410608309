const primaryMain = '#081A1B';
const primaryMainOpacity = 'rgba(8, 26, 27, 0.9)';
const secondaryMain = '#348339';
const secondaryMainOpacity = 'rgba(52, 131, 57, 0.5)';
const secondaryLight = '#84BD00';
const tertiaryMain = '#3D3D3D';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
