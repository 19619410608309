const primaryMain = '#1E5C8D';
const primaryMainOpacity = 'rgba(30, 92, 141, 0.9)';
const secondaryMain = '#111C4E';
const secondaryMainOpacity = 'rgba(17, 28, 78, 0.5)';
const secondaryLight = '#FFCD00';
const tertiaryMain = '#003359';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
