import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import DialogContentText from '@material-ui/core/DialogContentText';

const styles = theme => ({
  dialogText: {
    paddingBottom: theme.spacing()
  },
  email: {
    color: theme.palette.text.primaryText
  }
});

export const VerifyDialogInstructions = ({ classes, email }) => {
  return (
    <>
      <DialogContentText className={classes.dialogText}>
        {
          'Our records indicate that we already have data in our system associated with this email address: '
        }
        <span className={classes.email}>{email}</span>
      </DialogContentText>

      <DialogContentText className={classes.dialogText}>
        With your permission, we would like to import this data into your application.
      </DialogContentText>
      <DialogContentText className={classes.dialogText}>
        <b>To continue, click the button below and check your email for the validation code.</b>
      </DialogContentText>
    </>
  );
};

VerifyDialogInstructions.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired
};

export default withStyles(styles)(VerifyDialogInstructions);
