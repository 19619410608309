const primaryMain = '#0D4F45';
const primaryMainOpacity = 'rgba(13, 79, 69, 0.9)';
const secondaryMain = '#256EE6';
const secondaryMainOpacity = 'rgba(37, 111, 230, 0.5)';
const secondaryLight = '#1CAA95';
const tertiaryMain = '#1CAA95';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
