import React from 'react';
import PropTypes from 'prop-types';

import { changeFavicon } from '../../utils/favicon';

export default class SchoolFavicon extends React.PureComponent {
  componentDidMount() {
    changeFavicon(this.props.schoolCode);
  }

  render() {
    return null;
  }
}

SchoolFavicon.propTypes = {
  schoolCode: PropTypes.string.isRequired
};
