import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import SchoolTypography from '@shorelight/super-form/components/SchoolTypography';

import acLiveUMBLogo from '../../images/aclive_umb_logo.svg';
import aclaLogo from '../../images/acla-logo.svg';
import aclLogo from '../../images/acl-logo.svg';
import acdcLogo from '../../images/acdc_logo.svg';
import adelphiLogo from '../../images/adelphi-logo.svg';
import amdaLogo from '../../images/amda_logo.svg';
import ausLogo from '../../images/aus_logo.svg';
import americanLogo from '../../images/american_logo.svg';
import auburnLogo from '../../images/auburn-logo.svg';
import aumLogo from '../../images/aum_logo.svg';
import barLogo from '../../images/bar_logo.svg';
import beuLogo from '../../images/beu_logo.svg';
import bmuLogo from '../../images/bmu_logo.svg';
import blcLogo from '../../images/blc_logo.svg';
import brgLogo from '../../images/brg_logo.svg';
import bufLogo from '../../images/buf_logo.svg';
import btcLogo from '../../images/btc_logo.svg';
import carLogo from '../../images/car_logo.svg';
import ccaLogo from '../../images/cca_logo.svg';
import cccLogo from '../../images/ccc_logo.svg';
import cscLogo from '../../images/csc_logo.svg';
import csuLogo from '../../images/csu_logo.svg';
import cmuLogo from '../../images/cmu_logo.svg';
import dayLogo from '../../images/day_logo.svg';
import decLogo from '../../images/dec_logo.svg';
import dwuLogo from '../../images/dwu_logo.svg';
import edgLogo from '../../images/edg_logo.svg';
import eurLogo from '../../images/eur_logo.svg';
import fiuLogo from '../../images/fiu_logo.svg';
import ffuLogo from '../../images/ffu_logo.svg';
import gonLogo from '../../images/gon_logo.svg';
import hanLogo from '../../images/han_logo.svg';
import hicLogo from '../../images/hic_logo.svg';
import hccLogo from '../../images/hcc_logo.svg';
import hwcLogo from '../../images/hwc_logo.svg';
import hwdLogo from '../../images/hwd_logo.svg';
import hweLogo from '../../images/hwe_logo.svg';
import hwukLogo from '../../images/hwuk_logo.svg';
import jacLogo from '../../images/jac_logo.svg';
import lakLogo from '../../images/lak_logo.svg';
import lewLogo from '../../images/lew_logo.svg';
import kuLogo from '../../images/KU_logo.svg';
import lycLogo from '../../images/lyc_logo.svg';
import lsuLogo from '../../images/lsu_logo.svg';
import lynLogo from '../../images/lyn_logo.svg';
import lyuLogo from '../../images/lyu_logo.svg';
import mercerLogo from '../../images/mer_logo.svg';
import mmuLogo from '../../images/mmu_logo.svg';
import msmLogo from '../../images/msm_logo.svg';
import mstLogo from '../../images/mst_logo.svg';
import mghLogo from '../../images/mgh_logo.svg';
import mvuLogo from '../../images/mvu_logo.svg';
import omiLogo from '../../images/omi_logo.svg';
import okcLogo from '../../images/okc_logo.svg';
import owuLogo from '../../images/owu_logo.svg';
import pbaLogo from '../../images/pba_logo.svg';
import pepLogo from '../../images/pep_logo.svg';
import ranLogo from '../../images/ran_logo.svg';
import rmuLogo from '../../images/rmu_logo.svg';
import rnbLogo from '../../images/rnb_logo.svg';
import rucLogo from '../../images/ruc_logo.svg';
import rvuLogo from '../../images/rvu_logo.svg';
import sbuLogo from '../../images/sbu_logo.svg';
import sboLogo from '../../images/sbo_logo.svg';
import schLogo from '../../images/sch_logo.svg';
import scuLogo from '../../images/scu_logo.svg';
import shuLogo from '../../images/shu_logo.svg';
import smmLogo from '../../images/smm_logo.svg';
import smcLogo from '../../images/smc_logo.svg';
import staLogo from '../../images/sta_logo.svg';
import stmLogo from '../../images/stm_logo.svg';
import sruLogo from '../../images/sru_logo.svg';
import towLogo from '../../images/tow_logo.svg';
import tccLogo from '../../images/tcc_logo.svg';
import uafLogo from '../../images/uaf_logo.svg';
import ucfLogo from '../../images/ucf_logo.svg';
import umaLogo from '../../images/uma_logo.svg';
import umbLogo from '../../images/umb_logo.svg';
import umuLogo from '../../images/umu_logo.svg';
import uneLogo from '../../images/une_logo.svg';
import unrLogo from '../../images/unr_logo.svg';
import uicLogo from '../../images/uic_logo.svg';
import uisLogo from '../../images/uis_logo.svg';
import uocLogo from '../../images/uoc_logo.svg';
import uodLogo from '../../images/uod_logo.svg';
import uopLogo from '../../images/uop_logo.svg';
import uorLogo from '../../images/uor_logo.svg';
import updLogo from '../../images/upd_logo.svg';
import uscLogo from '../../images/usc_logo.svg';
import utaLogo from '../../images/uta_logo.svg';
import uteLogo from '../../images/ute_logo.svg';
import utsaLogo from '../../images/utsa_logo.svg';
import uweLogo from '../../images/uwe_logo.svg';
import uwpLogo from '../../images/uwp_logo.svg';
import uwrLogo from '../../images/uwr_logo.svg';
import uwsLogo from '../../images/uws_logo.svg';
import uwwLogo from '../../images/uww_logo.svg';
import uwyLogo from '../../images/uwy_logo.svg';
import swuLogo from '../../images/SWU_logo.svg';
import tnsLogo from '../../images/tns_logo.svg';
import tulLogo from '../../images/tul_logo.svg';
import tusLogo from '../../images/tus_logo.svg';
import jhuLogo from '../../images/JHU_logo.svg';
import vwuLogo from '../../images/vwu_logo.svg';
import wduLogo from '../../images/wdu_logo.svg';
import wilLogo from '../../images/wil_logo.svg';
import witLogo from '../../images/wit_logo.svg';
import wjcLogo from '../../images/wjc_logo.svg';
import wtcLogo from '../../images/wtc_logo.svg';
import wneLogo from '../../images/wne_logo.svg';
import wouLogo from '../../images/wou_logo.svg';
import wusLogo from '../../images/wus_logo.svg';
import ycpLogo from '../../images/ycp_logo.svg';

import shorelightLogo from '../../images/sl_logo.svg';

const schoolCodeToLogo = {
  ACDC: acdcLogo,
  ACLA: aclaLogo,
  ACL: aclLogo,
  'ACL-UMB': acLiveUMBLogo,
  ADU: adelphiLogo,
  AMDA: amdaLogo,
  AMU: americanLogo,
  AU: auburnLogo,
  AUM: aumLogo,
  AUS: ausLogo,
  BAR: barLogo,
  BEU: beuLogo,
  BMU: bmuLogo,
  BLC: blcLogo,
  BRG: brgLogo,
  BUF: bufLogo,
  BTC: btcLogo,
  CAR: carLogo,
  CCA: ccaLogo,
  CCC: cccLogo,
  CSC: cscLogo,
  CSU: csuLogo,
  'CSU-live': csuLogo,
  CMU: cmuLogo,
  DAY: dayLogo,
  'DAY-live': dayLogo,
  DEC: decLogo,
  DWU: dwuLogo,
  EDG: edgLogo,
  FIU: fiuLogo,
  FFU: ffuLogo,
  GON: gonLogo,
  HAN: hanLogo,
  HIC: hicLogo,
  HCC: hccLogo,
  HMA: hwukLogo,
  HWC: hwcLogo,
  HWD: hwdLogo,
  HWE: hweLogo,
  HDB: hwdLogo,
  HWM: hwdLogo,
  HWUK: hwukLogo,
  JAC: jacLogo,
  LAK: lakLogo,
  LEW: lewLogo,
  LYC: lycLogo,
  LYU: lyuLogo,
  KU: kuLogo,
  LSU: lsuLogo,
  LYN: lynLogo,
  MER: mercerLogo,
  MSM: msmLogo,
  MST: mstLogo,
  MMU: mmuLogo,
  MGH: mghLogo,
  MVU: mvuLogo,
  OMI: omiLogo,
  OKC: okcLogo,
  OWU: owuLogo,
  PBA: pbaLogo,
  PEP: pepLogo,
  SBU: sbuLogo,
  SBO: sboLogo,
  STA: staLogo,
  SHU: shuLogo,
  SMM: smmLogo,
  SMC: smcLogo,
  SCH: schLogo,
  'STF-live': shorelightLogo,
  'UCB-live': shorelightLogo,
  SCU: scuLogo,
  STM: stmLogo,
  SRU: sruLogo,
  UAF: uafLogo,
  UCF: ucfLogo,
  UIC: uicLogo,
  UIS: uisLogo,
  'UMA-live': umaLogo,
  UMB: umbLogo,
  UMU: umuLogo,
  UNR: unrLogo,
  UNE: uneLogo,
  UOP: uopLogo,
  UOC: uocLogo,
  UOD: uodLogo,
  UOR: uorLogo,
  UPD: updLogo,
  USC: uscLogo,
  'USC-live': uscLogo,
  UTA: utaLogo,
  UTE: uteLogo,
  UTSA: utsaLogo,
  UWE: uweLogo,
  UWP: uwpLogo,
  UWR: uwrLogo,
  UWS: uwsLogo,
  UWW: uwwLogo,
  UWY: uwyLogo,
  RAN: ranLogo,
  RMU: rmuLogo,
  RNB: rnbLogo,
  RUC: rucLogo,
  RVU: rvuLogo,
  SWU: swuLogo,
  TCC: tccLogo,
  TNS: tnsLogo,
  TOW: towLogo,
  TUL: tulLogo,
  TUS: tusLogo,
  JHU: jhuLogo,
  EUR: eurLogo,
  VWU: vwuLogo,
  WDU: wduLogo,
  WIL: wilLogo,
  WIT: witLogo,
  WJC: wjcLogo,
  WTC: wtcLogo,
  WNE: wneLogo,
  WOU: wouLogo,
  WUS: wusLogo,
  YCP: ycpLogo
};

const schoolCodeToAlt = {
  ACDC: 'American Collegiate DC',
  ACL: 'American Collegiate Live',
  ACLA: 'American Collegiate LA',
  'ACL-UMB': 'American Collegiate Live - UMB',
  ADU: 'Adelphi University',
  AMDA: 'The American Musical and Dramatic Academy',
  AMU: 'American University',
  AU: 'Auburn University',
  AUS: 'Austin College',
  AUM: 'Auburn University at Montgomery',
  BAR: 'Barton College',
  BEU: 'Bellarmine University',
  BLC: 'Blackburn College',
  BMU: 'Belmont University',
  BRG: 'Bridgewater College',
  BUF: 'University at Buffalo',
  BTC: 'Bethel College',
  CAR: 'Carroll University',
  CCA: 'Cornish College of the Arts',
  CCC: "Corpus Christi and St. Mark's College",
  CSC: 'Colby Sawyer College',
  CSU: 'Cleveland State University',
  'CSU-live': 'Cleveland State University',
  CMU: 'Central Methodist University',
  DAY: 'University of Dayton',
  'DAY-live': 'University of Dayton',
  DEC: 'Dean College',
  DWU: 'Dakota Wesleyan University',
  EDG: 'Edgewood College',
  EUR: 'Eureka College',
  FIU: 'Florida International University',
  FFU: 'Fairfield University',
  GON: 'Gonzaga University',
  HAN: 'Hanover College',
  HIC: 'Hiram College',
  HCC: 'Holy Cross College',
  HMA: 'Heriot-Watt Malaysia Campus',
  HWC: 'Hartwick College',
  HWD: 'Heriot-Watt University Dubai',
  HWM: 'Heriot-Watt University Malaysia',
  HWE: 'Heriot-Watt University Edinburgh',
  HDB: 'Heriot-Watt University UAE',
  HWUK: 'Heriot-Watt University UK',
  JHU: 'Johns Hopkins University',
  JAC: 'Jacksonville University',
  LAK: 'Lakeland University',
  LEW: 'Lewis University',
  LYC: 'Lycoming College',
  KU: 'Kansas University',
  LSU: 'Louisiana State University',
  LYN: 'University of Lynchburg',
  LYU: 'Lynn University',
  MER: 'Mercer University',
  MSM: "Mount St. Mary's University (Maryland)",
  MST: 'Missouri University of Science and Technology',
  MMU: 'McMurry University',
  MGH: 'MGH Institute of Health Professions',
  MVU: 'Moravian University',
  OMI: 'University of Mississippi',
  OKC: 'Oklahoma City University',
  OWU: 'Ohio Wesleyan University',
  PBA: 'Palm Beach Atlantic University',
  PEP: 'Pepperdine University',
  RAN: 'Randolph College',
  RMU: 'Robert Morris University',
  RNB: 'Rutgers University- New Brunswick',
  RUC: 'Rutgers University-Camden',
  RVU: 'Roosevelt University',
  SCU: 'St. Catherine University',
  'STF-live': 'Stanford University',
  SWU: 'SouthWestern University',
  'UCB-live': 'University of California Berkeley',
  STA: 'St. Thomas Aquinas College',
  SBU: 'Stony Brook University',
  SBO: 'St. Bonaventure University',
  SCH: 'Schreiner University',
  SHU: 'Shenandoah University',
  SMM: "Saint Mary's University of Minnesota",
  SMC: "St. Mary's College of California",
  STM: 'University of Saint Mary (Kansas)',
  SRU: 'Salve Regina University',
  TCC: 'Trinity Christian College',
  TNS: 'The New School',
  TOW: 'Towson University',
  TUL: 'Tulane University',
  TUS: 'Tusculum University',
  UAF: 'University of Alaska Fairbanks',
  UCF: 'University of Central Florida',
  UIC: 'University of Illinois at Chicago',
  UIS: 'University of Illinois Springfield',
  'UMA-live': 'University of Massachusetts Amherst',
  UMB: 'University of Massachusetts Boston',
  UMU: 'University of Mount Union',
  UNE: 'University of New England',
  UNR: 'University of Nevada Reno',
  UOP: 'University of the Pacific',
  UOC: 'University of Charleston',
  UOD: 'University of Dubuque',
  UOR: 'University of Redlands',
  UPD: 'University of Portland',
  USC: 'University of South Carolina',
  'USC-live': 'University of South Carolina',
  UTA: 'University of Utah',
  UTE: 'Utah Tech University',
  UTSA: 'University of Texas at San Antonio',
  UWE: 'University of Wisconsin-Eau Claire',
  UWP: 'University of Wisconsin - Platteville',
  UWR: 'University of Wisconsin - River Falls',
  UWS: 'University of Wisconsin - Superior',
  UWW: 'University of Wisconsin-Whitewater',
  UWY: 'University of Wyoming',
  VWU: 'Virginia Wesleyan University',
  WDU: 'Widener University',
  WIL: 'Wilson College',
  WIT: 'Wentworth Institute of Technology',
  WJC: 'Washington & Jefferson College',
  WTC: 'Whittier College',
  WNE: 'Western New England University',
  WOU: 'Western Oregon University',
  WUS: 'Washington University in St. Louis',
  YCP: 'York College of Pennsylvania'
};

const styles = theme => ({
  header: {
    height: theme.header.containerHeightMobile,
    position: 'relative',
    backgroundColor: theme.header.backgroundColor || theme.palette.primary.main,
    padding: theme.spacing(2),
    boxShadow: '0px 0px 16px rgba(0,0,0,.15)',
    [theme.breakpoints.up('sm')]: {
      height: theme.header.containerHeight,
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: theme.header.paddingTop || theme.spacing(3),
      paddingBottom: theme.header.paddingBottom || theme.spacing(3)
    }
  },
  logoContainer: {
    marginBottom: theme.spacing(2),
    ...(theme.header.logoContainerMobile || {}),
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: 8,
      left: 24,
      ...(theme.header.logoContainer || {})
    }
  },
  logo: {
    height: '100%',
    maxWidth: '100%'
  },
  instructions: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing()
    }
  },
  textContainer: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      width: '100%',
      marginTop: 0,
      marginBottom: 0,
      paddingRight: theme.spacing(4)
    }
  },
  titleText: {
    fontSize: 14,
    letterSpacing: 0.58,
    textTransform: 'uppercase',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(0.5),
      marginTop: -5,
      textAlign: 'right'
    }
  },
  subtitleText: {
    lineHeight: 1.1,
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  }
});

export const SchoolHeader = ({ schoolCode, title, subtitle, classes }) => {
  return (
    <Grid container className={classes.header} alignItems="center">
      <Grid item xs={12} sm={4} className={classes.logoContainer}>
        <img
          src={schoolCodeToLogo[schoolCode]}
          className={classes.logo}
          alt={schoolCodeToAlt[schoolCode]}
        />
      </Grid>
      <Grid item xs={12} className={classes.textContainer}>
        <SchoolTypography color="contrast" className={classes.titleText}>
          <b>{title}</b>
        </SchoolTypography>
        {subtitle.split('\n').map((elem, index) => (
          <SchoolTypography key={index} color="contrast" className={classes.subtitleText}>
            {elem}
          </SchoolTypography>
        ))}
      </Grid>
    </Grid>
  );
};

SchoolHeader.propTypes = {
  schoolCode: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SchoolHeader);
