import React, { Component } from 'react';
import { Provider } from 'react-redux';

import RootContainer from './RootContainer';
import configureStore from '../../actions/configureStore';

const store = configureStore();

// Options for https://github.com/dozoisch/react-google-recaptcha#advanced-usage
// to use recaptcha.net url and be allowed in china (or whenever google is bloked)
window.recaptchaOptions = {
  useRecaptchaNet: true
};

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <RootContainer />
      </Provider>
    );
  }
}
