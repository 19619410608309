import { $black } from './commonColors';

const primaryMain = $black;
const primaryMainOpacity = 'rgba(0, 0, 0, 0.9)';
const secondaryMain = '#E7202B';
const secondaryMainOpacity = 'rgba(231, 32, 43, 0.5)';
const secondaryLight = '#FDB913';
const tertiaryMain = '#444';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
