const primaryMain = '#0055B8';
const primaryMainOpacity = 'rgba(0, 85, 184, 0.9)';
const secondaryMain = '#00053E';
const secondaryMainOpacity = 'rgba(0, 5, 62, 0.5)';
const secondaryLight = '#F0E904';
const tertiaryMain = '#00053E';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
