import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';

import CloseIcon from '@material-ui/icons/Close';

import SchoolTypography from '@shorelight/super-form/components/SchoolTypography';

const styles = theme => ({
  rootTitle: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.divider,
    padding: theme.spacing(2)
  },
  title: {
    fontWeight: 'bold'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(),
    top: theme.spacing(),
    color: theme.palette.grey[500]
  }
});

export const DialogTitle = ({ classes, onDialogClose, showCloseButton, title }) => {
  return (
    <MuiDialogTitle disableTypography className={classes.rootTitle}>
      <SchoolTypography color="primaryText" variant="h6">
        <b>{title}</b>
      </SchoolTypography>
      {showCloseButton && (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onDialogClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(DialogTitle);
