const red = '#B1040E';
const grey = '#5E5E5E';

const primaryMain = red;
const primaryMainOpacity = 'rgba(177, 4, 14, 0.9)';
const primaryDark = '#820000';
const secondaryMain = red;
const secondaryMainOpacity = '#DEDEDE';
const secondaryLight = red;
const secondaryDark = '#820000';
const tertiaryMain = '#2E2D29';
const headerColor = grey;

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryDark,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryDark,
  secondaryText: grey,
  tertiaryMain,
  logoContainer: {
    height: 24,
    marginTop: 26
  },
  logoContainerMobile: {
    height: 24
  },
  headerColor,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  },
  formTextBlock: {
    alertBackgroundColor: tertiaryMain
  }
};
