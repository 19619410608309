const primaryMain = '#0C233F';
const primaryMainOpacity = 'rgba(12, 35, 63, 0.9)';
const secondaryMain = '#0070b9';
const secondaryMainOpacity = 'rgba(0, 112, 185, 0.5)';
const secondaryLight = '#FFC62F';
const tertiaryMain = '#97999B';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};