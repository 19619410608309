import { REQUIRED_FIELDS_FOR_LEAD } from '../constants/constants';
import 'url-search-params-polyfill';

/* Determines if a field is hidden
* @param {object} field - object representing the field in the schema that we are checking if hidden
*/
export const isHidden = field => field.hidden === true || field.type === 'hidden';

/* Pulls out IDs of all fields in a form schema
* @param {object} schema - the form schema that comes from the fetchForm call to the /forms api
*/
export function getFieldIds(schema = { pages: [] }) {
  const ids = [];
  schema.pages.forEach(page => {
    page.groups.forEach(group => {
      group.fields.forEach(field => {
        ids.push(field.id);
        if (field.type === 'subfields') {
          field.fields.forEach(subfield => {
            ids.push(subfield.id);
          });
        }
      });
      group.subgroups &&
        group.subgroups.forEach(subgroup => {
          subgroup.fields &&
            subgroup.fields.forEach(subgroupfield => {
              ids.push(subgroupfield.id);
            });
        });
    });
  });
  return ids;
}

/**
 * function that receives the current values and errors in the form
 * and return a function that receives the field Id and return a boolean
 * indicating if the field is valid in the form (has a value and no error related)
 *
 * @param {object} formValues
 * @param {object} syncErrors
 */
const isFieldValidInForm = (formValues, syncErrors) => fieldName =>
  formValues[fieldName] && !syncErrors[fieldName];

/**
 * function that receives the current values and errors in the form
 * and return if all fields indicated by REQUIRED_FIELDS_FOR_LEAD are valid
 * acorrding to the function isFieldValidInForm
 */
export const canCreateLead = (currentValues, syncErrors) => {
  const fieldValidator = isFieldValidInForm(currentValues, syncErrors);
  return REQUIRED_FIELDS_FOR_LEAD.every(fieldValidator);
};

/**
 * function to grab url query string params
 * returns an object of key-value pairs
 */
export const getQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let result = {};
  for (let entry of urlParams.entries()) {
    const key = entry[0].replace(/(school|level)/i, 'cfm_$1');
    result[key] = entry[1];
  }
  return result;
};

/**
 * Function provided to redux-form to have full control over when async validation happens.
 * Async Validation should happen for email term and Year fields, but term and year fields do
 * not have a fixed name/id they depend on school i.e can be termAU, termADU, termYearADU,
 * so we cannot provide them as static value of `asyncBlurFields` prop to reduxForm  HOC
 *
 * @param {*} param see redux-form docs for more details on parameters received bu the function
 */
export const shouldFormAsyncValidate = ({ trigger, syncValidationPasses } = {}) => {
  // do not async validate on submit
  return trigger === 'blur' && syncValidationPasses;
};
