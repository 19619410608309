const primaryMain = '#333333';
const primaryMainOpacity = 'rgba(51, 51, 51, 0.9)';
const secondaryMain = '#003465';
const secondaryMainOpacity = 'rgba(27, 62, 110, 0.5)';
const secondaryLight = '#DEF5FC';
const tertiaryMain = '#F6F6F6';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  contrastText: primaryMain,
  logoContainer: {
    height: 60,
    marginTop: 8
  },
  logoContainerMobile: {
    height: 50
  },
  headerColor: tertiaryMain,
  formNavBar: {
    stepperBackgroundColor: secondaryMain,
    stepperBackgroundColorMobile: secondaryMain
  }
};
