const primaryMain = '#005BBB';
const primaryMainOpacity = 'rgba(0, 91, 187, 0.9)';
const secondaryMain = '#E56A54';
const secondaryMainOpacity = 'rgba(229, 106, 84, 0.5)';
const secondaryLight = '#002F56';
const tertiaryMain = '#666666';

export const palette = {
  primaryMain,
  primaryMainOpacity,
  primaryText: primaryMain,
  secondaryMain,
  secondaryMainOpacity,
  secondaryLight,
  secondaryText: secondaryMain,
  tertiaryMain,
  formNavBar: {
    currentStepButtonTextColor: tertiaryMain
  }
};
