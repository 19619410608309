import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import FormFieldHelperLabel from '@shorelight/super-form/components/FormFieldHelperLabel';
import FormProgressButton from '@shorelight/super-form/components/FormProgressButton';

const styles = theme => ({
  root: {
    height: '100vh',
    backgroundColor: theme.palette.fieldset.main,
    paddingTop: theme.spacing(5)
  },
  container: {
    maxWidth: 900,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5)
    }
  },
  successHeader: {
    fontSize: 40,
    fontWeight: 600,
    textAlign: 'left'
  },
  helper: {
    fontSize: 18,
    textAlign: 'left',
    maxWidth: 720,
    margin: theme.spacing(3, 0, 5)
  },
  successHeaderText: theme.palette.successHeaderText
});

export class SuccessPage extends React.Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  handleBackToMain = () => {
    const { schoolUrl } = this.props;
    window.location = schoolUrl;
  };

  render() {
    const { classes, currentLevel } = this.props;
    const backButtonText =
      currentLevel === 'certificate' ? 'Return to Program' : 'Return to University';
    return (
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Typography
              color={classes.successHeaderText}
              variant="h3"
              className={classes.successHeader}
              align="center"
            >
              Thank you for applying!
            </Typography>
            <FormFieldHelperLabel className={classes.helper}>
              We have sent you a <b>confirmation email</b> with instructions on how to check your
              application status, contact admissions, and update your profile. If you have applied
              to more than one Shorelight school, this new application will be listed as Application
              In Review.
            </FormFieldHelperLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormProgressButton
              fullWidth
              id="back-btn"
              color="secondary"
              variant="contained"
              onClick={this.handleBackToMain}
            >
              {backButtonText}
            </FormProgressButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SuccessPage.propTypes = {
  currentLevel: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SuccessPage);
