import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import SchoolTypography from '@shorelight/super-form/components/SchoolTypography';

const styles = theme => ({
  introContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  },
  externalTextContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 636
    }
  },
  headerExternalText: {
    fontSize: 17,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  }
});

export const CustomTitle = ({ classes, title }) => {
  return (
    <Grid container className={classes.introContainer} justify="center">
      <Grid item xs={12} className={classes.externalTextContainer}>
        {title.split('\n').map((elem, index) => (
          <SchoolTypography
            key={index}
            color="textSecondary"
            className={classes.headerExternalText}
          >
            <b>{elem}</b>
          </SchoolTypography>
        ))}
      </Grid>
    </Grid>
  );
};

CustomTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(CustomTitle);
